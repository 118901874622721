<template>
    <div style="height:calc(100% - 1rem);  overflow: hidden;">
        <vueSeamlessScroll :data="listData" class="seamless-warp" :class-option="classOption">

            <div v-for="(item, index) in listData" :key="index" class="box">
                <div class="left" :style="{
                    'background-image': 'url(' + item.ico + ')',
                    'background-repeat': 'no-repeat',
                }" style="height: 100%; width: 1.13rem; background-size: 100% 100%">

                </div>
                <div class="right">
                    <div class="title">
                        <div>{{ item.title }}</div>
                    </div>
                    <div class="dz">
                        <div :style="{
                            'background-image': 'url(' + item.icojg + ')',
                            'background-repeat': 'no-repeat',
                        }" style="height:1rem; width: 1rem; background-size: 100% 100%;margin-right: 0.5rem;"></div>
                        {{ item.jg }}
                    </div>
                    <div class="time">
                        <div :style="{
                            'background-image': 'url(' + item.icotime + ')',
                            'background-repeat': 'no-repeat',
                        }" style="height:1rem; width: 1rem; background-size: 100% 100%;margin-right: 0.5rem;"></div>
                        {{ item.time }}
                    </div>

                </div>
            </div>

        </vueSeamlessScroll>
    </div>
</template>

<script>
import vueSeamlessScroll from "vue-seamless-scroll";
export default {
    name: 'ChanghuCheck',
    components: {
        vueSeamlessScroll,
    },

    // 设置计算属性
    computed: {
        classOption() {
            return {
                step: 0.2, // 数值越大速度滚动越快
                limitMoveNum: this.listLenght, // 开始无缝滚动的数据量 this.dataList.length
                hoverStop: true, // 是否开启鼠标悬停stop
                direction: 1, // 0向下 1向上 2向左 3向右
                openWatch: true, // 开启数据实时监控刷新dom
                singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
                singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
                waitTime: 1000, // 单步运动停止的时间(默认值1000ms)
                isSingleRemUnit: true
            };
        },
    },
    data() {
        return {
            listData: [
                {
                    jg: '淄江社区老年人日间照料中心',
                    time: '2023-08-25',
                    ico: require("@/assets/content/pic_1.png"),
                    title: '淄江社区老年大学葫芦丝班开课',
                    icojg: require("@/assets/content/ic_dz.png"),
                    icotime: require("@/assets/content/ic_time.png")
                },
                {
                    jg: '桑杨社区老年人日间照料中心',
                    time: '2023-08-25',
                    ico: require("@/assets/content/pic_1.png"),
                    title: '形体提升课',
                    icojg: require("@/assets/content/ic_dz.png"),
                    icotime: require("@/assets/content/ic_time.png")
                },
                {
                    jg: '桑杨社区老年人日间照料中心',
                    time: '2023-08-29',
                    ico: require("@/assets/content/pic_1.png"),
                    title: '为老年人筛查眼部疾病',
                    icojg: require("@/assets/content/ic_dz.png"),
                    icotime: require("@/assets/content/ic_time.png")
                },
                {
                    jg: '桑杨社区老年人日间照料中心',
                    time: '2023-08-28',
                    ico: require("@/assets/content/pic_1.png"),
                    title: '为居民免费筛查眼部、牙齿疾病',
                    icojg: require("@/assets/content/ic_dz.png"),
                    icotime: require("@/assets/content/ic_time.png")
                },
                {
                    jg: '齐都花园社区第一日间照料中心',
                    time: '2023-06-22',
                    ico: require("@/assets/content/pic_1.png"),
                    title: '上门为老人免费理发',
                    icojg: require("@/assets/content/ic_dz.png"),
                    icotime: require("@/assets/content/ic_time.png")
                },
                {
                    jg: '淄江社区老年人日间照料中心',
                    time: '2023-08-25',
                    ico: require("@/assets/content/pic_1.png"),
                    title: '淄江社区老年大学葫芦丝班开课',
                    icojg: require("@/assets/content/ic_dz.png"),
                    icotime: require("@/assets/content/ic_time.png")
                },
                {
                    jg: '桑杨社区老年人日间照料中心',
                    time: '2023-09-04',
                    ico: require("@/assets/content/pic_1.png"),
                    title: '太极拳展演',
                    icojg: require("@/assets/content/ic_dz.png"),
                    icotime: require("@/assets/content/ic_time.png")
                },
                {
                    jg: '淄江社区老年人日间照料中心',
                    time: '2023-08-25',
                    ico: require("@/assets/content/pic_1.png"),
                    title: '淄江社区老年大学葫芦丝班开课',
                    icojg: require("@/assets/content/ic_dz.png"),
                    icotime: require("@/assets/content/ic_time.png")
                },
                {
                    jg: '淄江社区老年人日间照料中心',
                    time: '2023-08-25',
                    ico: require("@/assets/content/pic_1.png"),
                    title: '淄江社区老年大学葫芦丝班开课',
                    icojg: require("@/assets/content/ic_dz.png"),
                    icotime: require("@/assets/content/ic_time.png")
                },
            ],
            listLenght: ''
        };
    },

    mounted() {

    },

    methods: {

    },
};
</script>

<style lang="scss" scoped>
.seamless-warp {
    height: 100%;
}

.box {
    width: 100%;
    height: 5rem;
    margin-bottom: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: space-around;
    border-bottom: 1px solid #2e6757;

    .left {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: space-between;


        .jg {
            font-size: 0.38rem;
            font-family: Source Han Sans CN, Source Han Sans CN-Regular;
            font-weight: 400;
            text-align: left;
            color: #32beff;
            /* 禁止文本换行 */
            overflow: hidden;
            /* 隐藏超出容器的部分 */
            text-overflow: ellipsis;
            width: 100%;
        }
    }

    .right {
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: start;
        padding-left: 0.5rem;
        justify-content: space-between;

        .time {
            font-size: 0.68rem;
            font-family: Source Han Sans CN, Source Han Sans CN-Regular;
            font-weight: 400;
            text-align: right;
            color: #32beff;
            margin-bottom: calc(1.13rem - 0.78rem);
            display: flex;
            align-items: center;
            justify-content: space-around;
        }

        .dz {
            font-size: 0.68rem;
            font-family: Source Han Sans CN, Source Han Sans CN-Regular;
            font-weight: 400;
            text-align: left;
            color: #32beff;
            display: flex;
            align-items: center;

            white-space: nowrap;
            /* 禁止文本换行 */
            overflow: hidden;
            /* 隐藏超出容器的部分 */
            text-overflow: ellipsis;
            width: 90%;
        }

        .title {
            font-size: 1.13rem;
            font-family: Source Han Sans CN, Source Han Sans CN-Regular;
            font-weight: 400;
            text-align: center;
            color: #32beff;
        }
    }
}
</style>