<template>
  <div
    v-loading="loading"
    element-loading-text="加载中"
    element-loading-spinner="el-icon-loading"
    element-loading-background="rgba(0, 0, 0, 0.8)"
    class="hl-full-container flexVertical"
  >
    <!-- 表单s -->
    <el-drawer
      :title="dailogTitle"
      :visible.sync="dialogFormVisible"
      direction="rtl"
      size="100%"
      :modal="false"
      :destroy-on-close="true"
      class="el-drawer-hl"
      @close="handleClose"
    >
      <el-form
        ref="elForm"
        :model="formData"
        :disabled="submitType == 'view' ? true : false"
        :rules="rules"
        size="medium"
        class="padding15"
        label-width="120px"
      >
        <el-col :span="24">
          <el-row>
            <el-col :span="12">
              <el-form-item label="批次名称" prop="name">
                <el-input
                  v-model="formData.name"
                  placeholder="请输入批次名称"
                  clearable
                  :style="{ width: '100%' }"
                >
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="所属年度" prop="year">
                <el-date-picker
                  v-model="formData.year"
                  type="year"
                  value-format="yyyy"
                  placeholder="选择所属年度"
                  :style="{ width: '100%' }"
                >
                </el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="有效开始时间" prop="startTime">
                <el-date-picker
                  v-model="formData.startTime"
                  type="datetime"
                  value-format="timestamp"
                  placeholder="选择有效开始时间"
                  :style="{ width: '100%' }"
                >
                </el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="有效结束时间" prop="endTime">
                <el-date-picker
                  v-model="formData.endTime"
                  type="datetime"
                  value-format="timestamp"
                  placeholder="选择有效结束时间"
                  :style="{ width: '100%' }"
                >
                </el-date-picker>
              </el-form-item>
            </el-col>

            <el-col :span="12">
              <el-form-item label="适用范围" prop="scopeApplication">
                <el-input
                  v-model="formData.scopeApplication"
                  placeholder="请输入适用范围"
                  clearable
                  :style="{ width: '100%' }"
                >
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="服务对象人数" prop="serviceNum">
                <el-input
                  v-model="formData.serviceNum"
                  placeholder="请输入服务对象人数"
                  clearable
                  :style="{ width: '100%' }"
                >
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="描述" prop="describe">
                <el-input
                  v-model="formData.describe"
                  placeholder="请输入描述"
                  clearable
                  type="textarea"
                  :rows="3"
                  :style="{ width: '100%' }"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-col>

        <el-col :span="24">
          <el-row>
            <el-form-item size="large" style="text-align: center">
              <el-button
                v-show="submitType != 'view' ? true : false"
                class="qconfirm"
                type="primary"
                size="medium"
                @click="submitForm()"
                >提交</el-button
              >
              <el-button
                v-show="submitType != 'view' ? true : false"
                class="qcancle"
                size="medium"
                @click="resetForm()"
              >
                重置
              </el-button>
            </el-form-item>
          </el-row>
        </el-col>
      </el-form>
    </el-drawer>

    <!-- 分配表单s -->
    <el-drawer
      title="分配"
      :visible.sync="dialogRelVisible"
      direction="rtl"
      size="100%"
      :modal="false"
      :destroy-on-close="true"
      class="el-drawer-hl"
      @close="handleRelClose"
    >
      <el-form
        ref="elRelForm"
        :model="formRelData"
        size="medium"
        :rules="relRules"
        class="padding15"
        label-width="120px"
      >
        <el-col :span="24">
          <el-row>
            <el-col :span="12">
              <el-form-item label="服务组织" prop="batchOrgan">
                <el-select
                  v-model="formRelData.batchOrgan"
                  multiple
                  filterable
                  :style="{ width: '100%' }"
                  placeholder="请选择服务组织"
                >
                  <el-option
                    v-for="item in OrganList"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="服务项目" prop="batchItem">
                <el-select
                  v-model="formRelData.batchItem"
                  multiple
                  filterable
                  value-key="itemId"
                  :style="{ width: '100%' }"
                  placeholder="请选择服务项目"
                >
                  <el-option
                    v-for="item in ItemList"
                    :key="item.itemId"
                    :label="item.name"
                    :value="item"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <div
                class="batchView"
                :key="index"
                v-for="(item, index) in formRelData.batchItem"
              >
                <div style="margin-right: 20px">
                  <span
                    style="
                      margin-right: 20px;
                      color: #ff8754;
                      font-weight: bold;
                    "
                    >{{ item.name }}
                  </span>
                  价格(元)：
                  <el-input-number
                    size="small"
                    v-model="item.price"
                    :precision="1"
                    :step="0.5"
                    :min="0"
                  ></el-input-number>
                </div>
                <div style="margin-right: 20px">
                  时长(小时)：
                  <el-input-number
                    size="small"
                    v-model="item.duration"
                    :precision="1"
                    :step="0.5"
                    :min="0"
                  ></el-input-number>
                </div>
                <div>
                  状态：
                  <el-switch
                    v-model="item.status"
                    :active-value="1"
                    :inactive-value="0"
                    active-text="上架"
                    inactive-text="下架"
                  >
                  </el-switch>
                </div>
              </div>
            </el-col>
          </el-row>
        </el-col>

        <el-col :span="24">
          <el-row>
            <el-form-item size="large" style="text-align: center">
              <el-button
                class="qconfirm"
                type="primary"
                size="medium"
                @click="submitRelForm()"
              >
                提交
              </el-button>
              <el-button class="qcancle" size="medium" @click="resetRelForm()">
                重置
              </el-button>
            </el-form-item>
          </el-row>
        </el-col>
      </el-form>
    </el-drawer>

    <div class="search">
      <el-form :model="form" ref="form" inline>
        <el-form-item label="姓名">
          <el-input
            size="small"
            clearable
            v-model="form.name"
            placeholder="请输入姓名"
          ></el-input>
        </el-form-item>
        <el-form-item label-width="120px" label="所属年度">
          <el-date-picker
            v-model="form.year"
            size="small"
            type="year"
            value-format="yyyy"
            placeholder="选择所属年度"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-button
            size="small"
            type="primary"
            icon="el-icon-search"
            @click="dataRefresh"
            >查询</el-button
          >
        </el-form-item>
      </el-form>
    </div>
    <div class="toolbar">
      <el-button
        type="primary"
        plain
        size="mini"
        icon="el-icon-plus"
        @click="Add"
        >新增</el-button
      >
      <el-button
        type="danger"
        plain
        size="mini"
        icon="el-icon-delete"
        @click="Del"
        >删除</el-button
      >
    </div>
    <hltable
      :isNumber="false"
      :columnEdit="TableOptions.columnEdit"
      :formSearch="form"
      :refreshFlag="TableOptions.refreshFlag"
      :tableColumn="TableOptions.column"
      :selectedRows="TableOptions.selectedRows"
      :apiurl="TableOptions.apiurl"
      @getSelectedRows="getSelectedRows"
    >
      <div class="edit-column" slot-scope="selected" slot="EditColumn">
        <span
          @click="View(selected.singlerow)"
          style="margin-right: 15px; cursor: pointer"
        >
          查看
        </span>
        <span
          @click="Edit(selected.singlerow)"
          style="margin-right: 15px; cursor: pointer"
        >
          编辑
        </span>
        <span @click="Rel(selected.singlerow)" style="cursor: pointer">
          分配
        </span>
      </div>
    </hltable>
  </div>
</template>

<script>
import { hltable } from "@/components";
export default {
  name: "fwpcgl",
  components: { hltable },
  data() {
    return {
      loading: false,
      token: { token: localStorage.getItem("systoken") },
      // 表单内容
      dailogTitle: "",
      dialogFormVisible: false,
      submitType: "",
      // 查询条件
      form: {
        name: "",
        year: "",
      },
      // 新增/修改 表单
      formData: {
        // 批次名称
        name: "",
        // 所属年度
        year: "",
        // 有效开始时间（时间戳）
        startTime: "",
        // 有效结束时间（时间戳）
        endTime: "",
        // 适用范围
        scopeApplication: "",
        // 服务对象人数
        serviceNum: "",
        // 描述
        describe: "",
      },
      // 表单规则
      rules: {
        name: [
          {
            required: true,
            message: "请输入批次名称",
            trigger: "blur",
          },
        ],
        year: [
          {
            required: true,
            message: "所属年度",
            trigger: "change",
          },
        ],
        startTime: [
          {
            required: true,
            message: "请选择有效开始时间",
            trigger: "change",
          },
        ],
        endTime: [
          {
            required: true,
            message: "请选择有效结束时间",
            trigger: "change",
          },
        ],
        scopeApplication: [
          {
            required: true,
            message: "请输入适用范围",
            trigger: "blur",
          },
        ],
        serviceNum: [
          {
            required: true,
            message: "请输入服务对象人数",
            trigger: "blur",
          },
        ],
        describe: [
          {
            required: true,
            message: "请输入描述",
            trigger: "blur",
          },
        ],
      },
      // 分配表单
      dialogRelVisible: false,
      formRelData: {
        batchId: [],
        batchOrgan: [],
        batchItem: [],
      },
      // 分配表单规则
      relRules: {
        batchOrgan: [
          {
            type: "array",
            required: true,
            message: "至少选择一个服务组织",
            trigger: "change",
          },
        ],
        batchItem: [
          {
            type: "array",
            required: true,
            message: "至少选择一个服务项目",
            trigger: "change",
          },
        ],
      },
      // 列表设置
      TableOptions: {
        selectedRows: [],
        apiurl: "jjyl/getliveServiceBatchList",
        refreshFlag: "",
        columnEdit: true,
        column: [
          {
            label: "批次名称",
            prop: "name",
            checked: true,
          },
          {
            label: "所属年度",
            prop: "year",
            checked: true,
          },
          {
            label: "有效开始时间",
            prop: "startTime",
            checked: true,
            formatter: this.timeFormatter,
          },
          {
            label: "有效结束时间",
            prop: "endTime",
            checked: true,
            formatter: this.timeFormatter,
          },
          {
            label: "适用范围",
            prop: "scopeApplication",
            checked: true,
          },
          {
            label: "服务对象人数",
            prop: "serviceNum",
            checked: true,
          },
          {
            label: "服务总金额",
            prop: "totalAmount",
            checked: true,
          },
          {
            label: "服务总时长",
            prop: "totalDuration",
            checked: true,
          },
        ],
      },
      // 服务组织列表数据
      OrganList: [],
      // 服务项目列表数据
      ItemList: [],
    };
  },
  async mounted() {
    await this.loadData();
    this.dataRefresh();
  },
  methods: {
    async loadData() {
      const OrganData = await this.$store.dispatch(
        "jjyl/getAllliveServiceOrgan",
        {}
      );
      const ItemData = await this.$store.dispatch(
        "jjyl/getAllliveServiceItem",
        {}
      );
      if (OrganData.success) {
        this.OrganList = OrganData.result;
      }
      if (ItemData.success) {
        ItemData.result.forEach((item) => {
          this.ItemList.push({
            itemId: item.id,
            name: item.name,
            status: 1,
            price: 0,
            duration: 0,
          });
        });
      }
    },
    // 查询
    dataRefresh() {
      this.TableOptions.refreshFlag = new Date().getTime().toString();
    },
    // 时间格式化
    timeFormatter(row, column, cellValue) {
      return cellValue ? this.$timestampTostr(cellValue) : "";
    },
    // 新增
    Add() {
      this.setformType("add", "新增");
    },
    // 查看
    View(row) {
      this.setformType("view", "查看");
      this.loadform(row);
    },
    // 编辑
    Edit(row) {
      this.setformType("edit", "编辑");
      this.loadform(row);
    },
    // 分配
    Rel(row) {
      if (
        row.batchOrgan &&
        row.batchOrgan.length > 0 &&
        row.batchItem &&
        row.batchItem.length > 0
      ) {
        this.formRelData = {
          batchId: row.id,
          batchOrgan: row.batchOrgan,
          batchItem: row.batchItem,
        };
      } else {
        this.formRelData.batchId = row.id;
      }
      this.dialogRelVisible = true;
    },
    batchItemChange(row) {},
    // 设置表单状态
    setformType(type, title) {
      this.dialogFormVisible = true;
      this.dailogTitle = title;
      this.submitType = type;
    },
    loadform(row) {
      const rowinfo = Object.assign({}, row);
      this.formData = rowinfo;
    },
    // 弹窗关闭前处理
    handleClose() {
      this.resetForm();
    },
    handleRelClose() {
      this.resetRelForm();
    },
    // 删除
    Del() {
      if (this.TableOptions.selectedRows.length == 0) {
        this.$message({
          type: "error",
          message: "请勾选下方表格中的数据",
        });
        return false;
      }
      var ids = [];
      this.TableOptions.selectedRows.forEach((key) => {
        ids.push(key.id);
      });
      this.$confirm("确定要进行删除吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.$store
          .dispatch("jjyl/delliveServiceBatch", { ids: ids })
          .then((response) => {
            if (response.success) {
              this.$message.success("操作成功");
              this.dataRefresh();
            } else {
              this.$message.error(response.message);
            }
          })
          .catch((error) => {
            console.log(error);
          });
      });
    },
    // 提交
    submitForm() {
      this.$refs["elForm"].validate((valid) => {
        if (valid) {
          this.loading = true;
          const copyForm = Object.assign({}, this.formData);
          if (this.submitType == "add") {
            copyForm.id = "";
          }
          this.$store
            .dispatch("jjyl/saveliveServiceBatch", copyForm)
            .then((response) => {
              if (response.success) {
                this.$message.success("操作成功");
                this.dataRefresh();
              } else {
                this.$message.error(response.message);
              }
              this.dialogFormVisible = false;
              this.loading = false;
            })
            .catch((error) => {
              this.loading = false;
              console.log(error);
            });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    submitRelForm() {
      this.$refs["elRelForm"].validate((valid) => {
        if (valid) {
          this.loading = true;
          const copyForm = Object.assign({}, this.formRelData);
          this.$store
            .dispatch("jjyl/saveBatchRel", copyForm)
            .then((response) => {
              if (response.success) {
                this.$message.success("操作成功");
              } else {
                this.$message.error(response.message);
              }
              this.dialogRelVisible = false;
              this.loading = false;
            })
            .catch((error) => {
              this.loading = false;
              console.log(error);
            });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    // 重置
    resetForm() {
      const th = this;
      Object.keys(this.formData).forEach(function (key) {
        th.formData[key] = "";
      });
    },
    resetRelForm() {
      const th = this;
      Object.keys(this.formRelData).forEach(function (key) {
        th.formRelData[key] = [];
      });
    },

    // 获取选中项
    getSelectedRows(rows) {
      this.TableOptions.selectedRows = rows;
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/styles/tabletoptool.scss";
.batchView {
  width: 100%;
  height: 50px;
  display: flex;
  padding-left: 50px;
  align-items: center;
}
</style>
