<template>
  <div class="grjws">
    <div class="title">
      助餐订单<span>（数据来源助餐数据汇总库，最新更新日期2023-10-20）</span>
    </div>
    <div class="content">
      <div class="search">
        <el-form :inline="true" class="demo-form-inline">
          <el-form-item label="服务日期">
            <el-date-picker v-model="searchData.startDate" type="date" placeholder="选择日期">
            </el-date-picker>
            -
            <el-date-picker v-model="searchData.endDate" type="date" placeholder="选择日期">
            </el-date-picker>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="onSubmit">查询</el-button>
            <el-button @click="resetForm()">重置</el-button>
          </el-form-item>
        </el-form>
      </div>
      <div class="tableBox">
        <hltable :columnEdit="table.columnEdit" :tableColumn="table.column" :apiurl="table.apiurl"
          :refreshFlag="table.refreshFlag" :selectedRows="table.selectedRows" :isChecked="table.isChecked"
          :isNumber="table.isNumber" :formSearch="query">
          <div slot-scope="selected" slot="EditColumn">
            <el-button icon="el-icon-delete" type="text" size="small" @click="delete selected.singlerow">删除</el-button>
          </div>
        </hltable>
      </div>
    </div>
  </div>
</template>

<script>
import { hltable } from "@/components";
export default {
  name: "YanglaoSyr",
  components: { hltable },
  data() {
    return {
      query: {
        oldName: "",
        organ: "",
        sex: "",
        level: "",
        area: "",
        status: "",
        idCard: "",
        phone: "",
      },
      table: {
        columnEdit: true,
        column: [
          {
            label: "助餐站点",
            prop: "oldName",
            checked: true,
          },
          {
            label: "老人姓名",
            prop: "2",
            checked: true,
          },
          {
            label: "老人身份证",
            prop: "organName",
            checked: true,
          },
          {
            label: "补助类型",
            prop: "oldName",
            checked: true,
          },
          {
            label: "套餐金额(元)",
            prop: "2",
            checked: true,
          },
          {
            label: "实付金额(元)",
            prop: "organName",
            checked: true,
          },
          {
            label: "补贴金额(元)",
            prop: "organName",
            checked: true,
          },
          {
            label: "配餐厨房",
            prop: "organName",
            checked: true,
          },
          {
            label: "订单来源",
            prop: "oldName",
            checked: true,
          },
          {
            label: "订单类型",
            prop: "2",
            checked: true,
          },
          {
            label: "餐品类型",
            prop: "organName",
            checked: true,
          },
          {
            label: "菜品名称",
            prop: "organName",
            checked: true,
          },
          {
            label: "就餐日期",
            prop: "2",
            checked: true,
          },
          {
            label: "就餐时段",
            prop: "organName",
            checked: true,
          },
          {
            label: "订单创建时间",
            prop: "organName",
            checked: true,
          },
        ],
        apiurl: "lrxx/getDataList",
        refreshFlag: "",
        selectedRows: [],
        isChecked: false,
        isNumber: true,
      },
      searchData: {
        startDate: "",
        endDate: "",
      },
    };
  },

  mounted() { },

  methods: {
    resetForm() {
      Object.keys(this.searchData).forEach((k) => (this.searchData[k] = ""));
    },
  },
};
</script>

<style lang="scss" scoped>
.grjws {
  width: 100%;

  button {
    margin: 1rem;
  }

  .tableBox {
    height: 30rem;
    width: 100%;
  }
}

::v-deep .el-form {
  display: flex;
  align-items: center;
}

.title {
  width: 98%;
  border-bottom: 1px solid #999;
  padding-bottom: 0.5rem;
  margin: 1rem auto;
  font-size: 1rem;
  color: rgb(39, 39, 39);
}
</style>