<template>
  <div
    v-loading="loading"
    element-loading-text="加载中"
    element-loading-spinner="el-icon-loading"
    element-loading-background="rgba(0, 0, 0, 0.8)"
    class="hl-full-container flexVertical"
  >
    <div class="search">
      <el-form :model="form" ref="form" inline>
        <el-form-item label="服务对象名称">
          <el-input
            size="small"
            clearable
            v-model="form.name"
            placeholder="请输入服务对象名称"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button
            size="small"
            type="primary"
            icon="el-icon-search"
            @click="dataRefresh"
            >查询</el-button
          >
        </el-form-item>
      </el-form>
    </div>

    <hltable
      :isChecked="false"
      :isNumber="false"
      :columnEdit="TableOptions.columnEdit"
      :formSearch="form"
      :refreshFlag="TableOptions.refreshFlag"
      :tableColumn="TableOptions.column"
      :selectedRows="TableOptions.selectedRows"
      :apiurl="TableOptions.apiurl"
      @getSelectedRows="getSelectedRows"
    >
    </hltable>
  </div>
</template>

<script>
import { hltable } from "@/components";
export default {
  name: "znsbjr",
  components: { hltable },
  data() {
    return {
      loading: false,
      // 查询条件
      form: {
        name: "",
      },
      // 列表设置
      TableOptions: {
        selectedRows: [],
        apiurl: "jtcw/geteqElderlyList",
        refreshFlag: "",
        columnEdit: false,
        column: [
          {
            label: "服务对象名称",
            prop: "name",
            checked: true,
          },
          {
            label: "联系电话",
            prop: "phone",
            checked: true,
          },
          {
            label: "设备名称",
            prop: "equipmentName",
            checked: true,
          },
          {
            label: "设备型号",
            prop: "equipmentModel",
            checked: true,
          },
          {
            label: "设备sn",
            prop: "sn",
            checked: true,
          },
          {
            label: "批次名称",
            prop: "batchName",
            checked: true,
          },
          {
            label: "身份证号",
            prop: "idCard",
            checked: true,
          },
          {
            label: "设备类型",
            prop: "type",
            checked: true,
          },
        ],
      },
    };
  },
  mounted() {
    this.dataRefresh();
  },
  methods: {
    // 查询
    dataRefresh() {
      this.TableOptions.refreshFlag = new Date().getTime().toString();
    },
    // 获取选中项
    getSelectedRows(rows) {
      this.TableOptions.selectedRows = rows;
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/styles/tabletoptool.scss";
</style>
