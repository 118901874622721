import request from '@/utils/request'

export function saveData(data) {
  return request({
    url: '/api/sysRole/saveData.json',
    method: 'post',
    data
  })
}

export function getAllList(data) {
  return request({
    url: '/api/sysRole/getAllList.json',
    method: 'get',
    params:data
  })
}

export function getDataInfo(data) {
  return request({
    url: '/api/sysRole/getDataInfo.json',
    method: 'get',
    params:data
  })
}

export function getDataList(data) {
  return request({
    url: '/api/sysRole/getDataList.json',
    method: 'post',
    data
  })
}

export function deleteData(data) {
  return request({
    url: '/api/sysRole/deleteData.json',
    method: 'post',
    data
  })
}

// 【角色管理】多端登录
export function roleSaveData(data) {
  return request({
    url: '/api/sysRole/saveData.json',
    method: 'post',
    data
  })
}