<template>
  <div
    v-loading="loading"
    element-loading-text="加载中"
    element-loading-spinner="el-icon-loading"
    element-loading-background="rgba(0, 0, 0, 0.8)"
    class="hl-full-container flexVertical"
  >
    <!-- 表单s -->
    <el-drawer
      :title="dailogTitle"
      :visible.sync="dialogFormVisible"
      direction="rtl"
      size="100%"
      :modal="false"
      :destroy-on-close="true"
      class="el-drawer-hl"
      @close="handleClose"
    >
      <el-form
        ref="elForm"
        :model="formData"
        :disabled="submitType == 'view' ? true : false"
        :rules="rules"
        size="medium"
        class="padding15"
        label-width="120px"
      >
        <el-col :span="24">
          <el-row>
            <el-col :span="12">
              <el-form-item label="服务批次" prop="batchId">
                <el-select
                  v-model="formData.batchId"
                  filterable
                  clearable
                  @clear="batchClear"
                  @change="batchChange"
                  :style="{ width: '100%' }"
                  placeholder="请选择服务批次"
                >
                  <el-option
                    v-for="item in batchList"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                  >
                  </el-option>
                </el-select>
              </el-form-item>

              <el-form-item label="服务组织" prop="organId">
                <el-select
                  v-model="formData.organId"
                  filterable
                  clearable
                  :style="{ width: '100%' }"
                  placeholder="请选择服务组织"
                >
                  <el-option
                    v-for="item in organList"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                  >
                  </el-option>
                </el-select>
              </el-form-item>

              <el-form-item label="合约次数" prop="contractNum">
                <el-input
                  v-model="formData.contractNum"
                  placeholder="请输入合约次数"
                  clearable
                  :style="{ width: '100%' }"
                >
                </el-input>
              </el-form-item>
            </el-col>

            <el-col :span="24">
              <div
                class="batchView"
                :key="index"
                v-for="(item, index) in itemList"
              >
                <div style="margin-right: 20px">
                  <span
                    style="
                      margin-right: 20px;
                      color: #ff8754;
                      font-weight: bold;
                    "
                    >{{ item.name }}
                  </span>
                  价格(元)：
                  <span style="color: #ff8754; font-weight: bold">{{
                    item.price
                  }}</span>
                </div>
                <div style="margin-right: 20px">
                  时长(小时)：
                  <span style="color: #ff8754; font-weight: bold">{{
                    item.duration
                  }}</span>
                </div>
              </div>
            </el-col>
          </el-row>
        </el-col>

        <el-col :span="24">
          <el-row>
            <el-form-item size="large" style="text-align: center">
              <el-button
                v-show="submitType != 'view' ? true : false"
                class="qconfirm"
                type="primary"
                size="medium"
                @click="submitForm()"
                >提交</el-button
              >
              <el-button
                v-show="submitType != 'view' ? true : false"
                class="qcancle"
                size="medium"
                @click="resetForm()"
              >
                重置
              </el-button>
            </el-form-item>
          </el-row>
        </el-col>
      </el-form>
    </el-drawer>

    <div class="search">
      <el-form label-width="80px" :model="form" ref="form" inline>
        <el-form-item label="网格：">
          <el-cascader
            size="small"
            style="flex: 1"
            :options="areaData"
            v-model="areaCode"
            placeholder="请选择网格编码"
            clearable
            :props="{ value: 'code', label: 'name', checkStrictly: true }"
            :show-all-levels="false"
            @change="cascaderChanged"
          >
          </el-cascader>
        </el-form-item>
        <el-form-item label="老人姓名">
          <el-input
            size="small"
            clearable
            v-model="form.name"
            placeholder="请输入老人姓名"
          ></el-input>
        </el-form-item>
        <el-form-item label="身份证号">
          <el-input
            size="small"
            clearable
            v-model="form.idCard"
            placeholder="请输入姓名"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button
            size="small"
            type="primary"
            icon="el-icon-search"
            @click="dataRefresh"
            >查询</el-button
          >
        </el-form-item>
      </el-form>
    </div>
    <div class="toolbar">
      <el-button
        type="primary"
        plain
        size="mini"
        icon="el-icon-plus"
        @click="Add"
        >签约</el-button
      >
    </div>
    <hltable
      :isNumber="false"
      :columnEdit="TableOptions.columnEdit"
      :formSearch="form"
      :refreshFlag="TableOptions.refreshFlag"
      :tableColumn="TableOptions.column"
      :selectedRows="TableOptions.selectedRows"
      :apiurl="TableOptions.apiurl"
      @getSelectedRows="getSelectedRows"
    >
      <div slot-scope="selected" slot="EditColumn">
        <el-tooltip
          v-show="selected.singlerow.batchId"
          effect="dark"
          content="查看合约"
          placement="top"
        >
          <el-button
            type="warning"
            size="mini"
            icon="el-icon-search"
            circle
            @click="View(selected.singlerow)"
          ></el-button>
        </el-tooltip>
        <el-tooltip effect="dark" content="签约/修改" placement="top">
          <el-button
            type="primary"
            icon="el-icon-edit"
            size="mini"
            circle
            @click="Edit(selected.singlerow)"
          ></el-button>
        </el-tooltip>
        <el-tooltip
          v-show="selected.singlerow.personId"
          effect="dark"
          content="生成工单"
          placement="top"
        >
          <el-button
            type="success"
            icon="el-icon-s-order"
            size="mini"
            circle
            @click="Work(selected.singlerow)"
          ></el-button>
        </el-tooltip>
      </div>
    </hltable>
  </div>
</template>

<script>
import { hltable } from "@/components";
export default {
  name: "hygl",
  components: { hltable },
  data() {
    return {
      loading: false,
      // 表单内容
      dailogTitle: "",
      dialogFormVisible: false,
      submitType: "",
      //功能树-s
      areaCode: [],
      areaData: [],
      // 查询条件
      form: {
        name: "",
        idCard: "",
        areaCode: "",
      },
      // 新增/修改 表单
      formData: {
        batchId: "",
        organId: "",
        contractNum: "",
      },
      // 表单规则
      rules: {
        batchId: [
          {
            required: true,
            message: "请选择服务批次",
            trigger: "change",
          },
        ],
        organId: [
          {
            required: true,
            message: "请选择服务组织",
            trigger: "blur",
          },
        ],
        contractNum: [
          {
            required: true,
            message: "请输合约次数",
            trigger: "blur",
          },
        ],
      },
      // 列表设置
      TableOptions: {
        selectedRows: [],
        apiurl: "jjyl/getliveContractList",
        refreshFlag: "",
        columnEdit: true,
        column: [
          {
            label: "老人姓名",
            prop: "name",
            checked: true,
          },
          {
            label: "身份证号",
            prop: "idCard",
            checked: true,
          },
          {
            label: "能力等级",
            prop: "level",
            checked: false,
          },
          {
            label: "老人类别",
            prop: "oldType",
            checked: false,
          },
          {
            label: "合约号",
            prop: "code",
            checked: true,
          },
          {
            label: "合约状态",
            prop: "status",
            checked: true,
          },
          {
            label: "合约次数",
            prop: "contractNum",
            checked: true,
          },
          {
            label: "服务次数",
            prop: "serviceNum",
            checked: true,
          },
          {
            label: "签约时间",
            prop: "signTime",
            checked: true,
            formatter: this.timeFormatter,
          },
          {
            label: "服务批次",
            prop: "batch",
            checked: true,
          },
          {
            label: "服务组织",
            prop: "organ",
            checked: true,
          },
        ],
      },
      batchList: [],
      itemList: [],
      organList: [],
    };
  },
  async mounted() {
    await this.loadData();
    this.dataRefresh();
    this.getAreaTree();
  },
  methods: {
    // 获取网格信息
    getAreaTree() {
      this.$store
        .dispatch("api/getTree", {
          areaCode: this.$store.getters.user.areaCode,
        })
        .then((response) => {
          if (response.success) {
            this.areaData = response.result;
          } else {
            this.$message.error(response.message);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    // 加载所有批次
    async loadData() {
      const BatchData = await this.$store.dispatch(
        "jjyl/getAllliveServiceBatch",
        {}
      );
      if (BatchData.success) {
        this.batchList = BatchData.result;
      }
    },
    // 删除批次
    batchClear() {
      this.itemList = [];
      this.organList = [];
      this.formData.organId = "";
    },
    // 批次选择发生变化
    async batchChange(batchId) {
      this.batchClear();
      // 根据批次查询服务项目
      const itemData = await this.$store.dispatch("jjyl/getItemByBathId", {
        batchId,
      });
      // 根据批次查询服务组织
      const organData = await this.$store.dispatch("jjyl/getOrganByBathId", {
        batchId,
      });
      if (itemData.success) {
        this.itemList = itemData.result;
      }
      if (organData.success) {
        this.organList = organData.result;
      }
    },
    // 查询
    dataRefresh() {
      this.TableOptions.refreshFlag = new Date().getTime().toString();
    },
    // 时间格式化
    timeFormatter(row, column, cellValue) {
      return cellValue ? this.$timestampTostr(cellValue) : "";
    },
    // 新增
    Add() {
      this.setformType("add", "新增");
    },
    // 查看
    View(row) {
      this.setformType("view", "查看");
      this.loadform(row);
    },
    // 编辑
    Edit(row) {
      this.setformType("edit", "编辑");
      this.loadform(row);
    },
    // 生成工单
    async Work(row) {
      const workForm = {
        id: row.id,
        contractNum: row.contractNum,
        serviceNum: row.serviceNum,
        personId: row.personId,
        batchId: row.batchId,
        organId: row.organId,
      };
      this.loading = true;
      const workData = await this.$store.dispatch(
        "jjyl/addWorkOrder",
        workForm
      );
      if (workData.success) {
        this.$message.success("操作成功");
      } else {
        this.$message.error(workData.message || "操作失败");
      }
      this.loading = false;
    },
    // 设置表单状态
    setformType(type, title) {
      this.dialogFormVisible = true;
      this.dailogTitle = title;
      this.submitType = type;
    },
    async loadform(row) {
      if (row.batchId) await this.batchChange(row.batchId);
      const rowinfo = Object.assign({}, row);
      this.formData = {
        id: row.id,
        batchId: rowinfo.batchId,
        organId: rowinfo.organId,
        contractNum: rowinfo.contractNum,
      };
    },
    // 弹窗关闭前处理
    handleClose() {
      this.formData = {
        batchId: "",
        organId: "",
        contractNum: "",
      };
      this.itemList = [];
      this.organList = [];
    },
    // 提交
    submitForm() {
      this.$refs["elForm"].validate((valid) => {
        if (valid) {
          this.loading = true;
          const copyForm = Object.assign({}, this.formData);
          this.$store
            .dispatch("jjyl/saveliveContract", copyForm)
            .then((response) => {
              if (response.success) {
                this.$message.success("操作成功");
                this.dataRefresh();
              } else {
                this.$message.error(response.message);
              }
              this.dialogFormVisible = false;
              this.loading = false;
            })
            .catch((error) => {
              this.loading = false;
              console.log(error);
            });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    // 获取选中项
    getSelectedRows(rows) {
      this.TableOptions.selectedRows = rows;
    },
    cascaderChanged() {
      var [...village] = this.areaCode;
      this.form.areaCode = village.pop() || "";
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/styles/tabletoptool.scss";

.batchView {
  width: 100%;
  height: 50px;
  display: flex;
  padding-left: 50px;
  align-items: center;
}
</style>
