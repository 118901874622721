<template>
  <div class="hl-full-container flexVertical">
    <hltable
      :isNumber="true"
      :columnEdit="TableOptions.columnEdit"
      :formSearch="form"
      :refreshFlag="TableOptions.refreshFlag"
      :tableColumn="TableOptions.column"
      :selectedRows="TableOptions.selectedRows"
      :apiurl="TableOptions.apiurl"
      @getSelectedRows="getSelectedRows"
    >
    </hltable>
  </div>
</template>

<script>
import { hltable } from "@/components";
export default {
  name: "rechRecordHistory",
  components: { hltable },
  props: {
    childId: {
      type: Array,
    },
  },
  data() {
    return {
      form: {
        idCard: this.childId[0]
      },
      TableOptions: {
        selectedRows: [],
        apiurl: "api/getDataListAll",
        refreshFlag: "",
        columnEdit: false,
        column: [
          {
            label: "姓名",
            prop: "name",
            checked: true,
          },
          {
            label: "身份证号",
            prop: "idCard",
            checked: true,
          },
          {
            label: "助餐食堂",
            prop: "siteName",
            checked: true,
          },
          {
            label: "充值金额(元)",
            prop: "money",
            checked: true,
          },
          {
            label: "充值时间",
            prop: "createTime",
            checked: true,
            formatter: this.timeFormatter,
          },
        ],
      },
    };
  },
  mounted() {
    this.dataRefresh();
  },
  methods: {
    timeFormatter(row, column, cellValue) {
      return cellValue ? this.$timestampTostr(cellValue) : "";
    },
    dataRefresh() {
      this.TableOptions.refreshFlag = new Date().getTime().toString();
    },
    getSelectedRows(rows) {
      this.TableOptions.selectedRows = rows;
    },
  },
};
</script>

<style scoped lang="scss"></style>
