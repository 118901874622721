<template>
  <div
    v-loading="loading"
    element-loading-text="加载中"
    element-loading-spinner="el-icon-loading"
    element-loading-background="rgba(0, 0, 0, 0.8)"
    class="hl-full-container flexVertical"
  >
    <!-- 表单s -->
    <el-drawer
      :title="dailogTitle"
      :visible.sync="dialogFormVisible"
      direction="rtl"
      size="100%"
      :modal="false"
      :destroy-on-close="true"
      class="el-drawer-hl"
      @close="handleClose"
    >
      <el-form
        ref="elForm"
        :model="formData"
        :rules="rules"
        size="medium"
        class="padding15"
        label-width="120px"
      >
        <el-row>
          <el-col :span="12">
            <el-col :span="24">
              <el-form-item label="姓名" prop="elderlyName">
                <el-input
                  v-model="formData.elderlyName"
                  placeholder="请输入姓名"
                  clearable
                  :style="{ width: '100%' }"
                >
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="身份证号" prop="idCard">
                <el-input
                  v-model="formData.idCard"
                  placeholder="请输入身份证号"
                  clearable
                  :style="{ width: '100%' }"
                >
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="联系电话" prop="phone">
                <el-input
                  v-model="formData.phone"
                  placeholder="请输入联系电话"
                  clearable
                  :style="{ width: '100%' }"
                >
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="工单号" prop="code">
                <el-input
                  v-model="formData.code"
                  placeholder="请输入工单号"
                  clearable
                  :style="{ width: '100%' }"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="服务组织" prop="organId">
                <el-select
                  v-model="formData.organId"
                  filterable
                  clearable
                  :style="{ width: '100%' }"
                  placeholder="请选择服务组织"
                >
                  <el-option
                    v-for="item in OrganList"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>

            <el-row>
              <el-card header="服务对象签字">
                <el-image
                  :src="formData.serviceRecipientSignature"
                  style="width: 100%; height: 100px"
                  fit="contain"
                  :preview-src-list="[formData.serviceRecipientSignature]"
                ></el-image>
              </el-card>
              <el-card header="服务人员签字">
                <el-image
                  :src="formData.serviceStaffSignature"
                  style="width: 100%; height: 100px"
                  fit="contain"
                  :preview-src-list="[formData.serviceStaffSignature]"
                ></el-image>
              </el-card>
            </el-row>
          </el-col>
          <el-col :span="12">
            <el-collapse v-model="formData.collapseNames">
              <el-collapse-item
                v-for="(item, index) in formData.workItemList"
                :key="index"
                :title="item.itemName"
                :name="item.itemName"
              >
                <el-image
                  v-for="(img, iindex) in item.path.split(',')"
                  :src="img"
                  :key="iindex"
                  style="width: 60px; height: 60px"
                  fit="contain"
                  :preview-src-list="item.path.split(',')"
                ></el-image>
              </el-collapse-item>
            </el-collapse>
          </el-col>
        </el-row>

        <el-col v-if="formData.status == 4" :span="24">
          <el-row>
            <el-form-item
              size="large"
              label-width="0"
              style="text-align: center"
            >
              <el-button
                class="qconfirm"
                type="primary"
                size="medium"
                @click="submitForm(5)"
              >
                驳回
              </el-button>
              <el-button
                class="qconfirm"
                type="primary"
                size="medium"
                @click="submitForm(6)"
              >
                通过
              </el-button>
            </el-form-item>
          </el-row>
        </el-col>
      </el-form>
    </el-drawer>

    <div class="search">
      <el-form label-width="80px" :model="form" ref="form" inline>
        <el-form-item label="老人姓名">
          <el-input
            size="small"
            clearable
            v-model="form.elderlyName"
            placeholder="请输入老人姓名"
          ></el-input>
        </el-form-item>
        <el-form-item label="身份证号">
          <el-input
            size="small"
            clearable
            v-model="form.idCard"
            placeholder="请输入身份证号"
          ></el-input>
        </el-form-item>
        <el-form-item label="服务组织" prop="batchOrgan">
          <el-select
            size="small"
            v-model="form.organName"
            filterable
            clearable
            placeholder="请选择服务组织"
          >
            <el-option
              v-for="item in OrganList"
              :key="item.id"
              :label="item.name"
              :value="item.name"
            >
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item>
          <el-button
            size="small"
            type="primary"
            icon="el-icon-search"
            @click="dataRefresh"
            >查询</el-button
          >
        </el-form-item>
      </el-form>
    </div>
    <div class="toolbar">
      <el-button
        type="primary"
        plain
        size="mini"
        icon="el-icon-download"
        @click="Download"
        >导出
      </el-button>
    </div>
    <hltable
      :isNumber="false"
      :columnEdit="TableOptions.columnEdit"
      :formSearch="form"
      :refreshFlag="TableOptions.refreshFlag"
      :tableColumn="TableOptions.column"
      :selectedRows="TableOptions.selectedRows"
      :apiurl="TableOptions.apiurl"
      @getSelectedRows="getSelectedRows"
    >
      <div slot-scope="selected" slot="EditColumn">
        <el-tooltip effect="dark" content="查看" placement="top">
          <el-button
            type="warning"
            size="mini"
            icon="el-icon-search"
            circle
            @click="View(selected.singlerow)"
          ></el-button>
        </el-tooltip>
        <!-- <el-tooltip v-else effect="dark" content="审核" placement="top">
          <el-button
            type="primary"
            icon="el-icon-info"
            size="mini"
            circle
            @click="Edit(selected.singlerow)"
          ></el-button>
        </el-tooltip> -->
      </div>
    </hltable>
  </div>
</template>

<script>
import { hltable } from "@/components";
export default {
  name: "jjfwgdgl",
  components: { hltable },
  data() {
    return {
      loading: false,
      token: { token: localStorage.getItem("systoken") },
      // 表单内容
      dailogTitle: "",
      dialogFormVisible: false,
      // 查询条件
      form: {
        elderlyName: "",
        idCard: "",
        organName: "",
      },
      // 新增/修改 表单
      formData: {
        name: "",
        idCard: "",
        phone: "",
        age: "",
        organId: "",
      },
      // 表单规则
      rules: {
        name: [
          {
            required: true,
            message: "请输入姓名",
            trigger: "blur",
          },
        ],
        idCard: [
          {
            required: true,
            message: "请输入身份证号",
            trigger: "blur",
          },
        ],
        age: [
          {
            required: true,
            message: "请输入年龄",
            trigger: "blur",
          },
        ],
        phone: [
          {
            required: true,
            pattern:
              /^(0|86|17951)?(13[0-9]|14[579]|15[012356789]|16[2567]|17[01235678]|18[0-9]|19[012356789])[0-9]{8}$/,
            message: "请输入正确的手机号",
            trigger: "blur",
          },
        ],
        organId: [
          {
            required: true,
            message: "请选择服务组织",
            trigger: "change",
          },
        ],
      },

      // 任务状态：0：未开启 1：进行中 2：已结束
      TASK_STATUS: [
        { value: 1, label: "未开始" },
        { value: 2, label: "执行中" },
        { value: 3, label: "暂停" },
        { value: 4, label: "待审核" },
        { value: 5, label: "驳回" },
        { value: 6, label: "结束" },
      ],
      // 列表设置
      TableOptions: {
        selectedRows: [],
        apiurl: "jjyl/getliveWorkOrderList",
        refreshFlag: "",
        columnEdit: true,
        column: [
          {
            label: "老人姓名",
            prop: "elderlyName",
            checked: true,
          },
          {
            label: "老人身份证",
            prop: "idCard",
            checked: true,
          },
          {
            label: "老人手机号",
            prop: "phone",
            checked: true,
          },
          {
            label: "工单号",
            prop: "code",
            checked: true,
          },
          {
            label: "服务组织",
            prop: "organName",
            checked: true,
          },
          {
            label: "工单创建时间",
            prop: "createTime",
            checked: true,
            formatter: this.timeFormatter,
          },
          {
            label: "工单状态",
            prop: "status",
            checked: true,
            formatter: (row, column, cellValue) => {
              return this.TASK_STATUS.find((item) => item.value == cellValue)
                ?.label;
            },
          },
          {
            label: "老人地址",
            prop: "address",
            checked: true,
          },
        ],
      },
      OrganList: [],
    };
  },
  async mounted() {
    await this.loadData();
    this.dataRefresh();
  },
  methods: {
    // 查询
    dataRefresh() {
      this.TableOptions.refreshFlag = new Date().getTime().toString();
    },
    // 时间格式化
    timeFormatter(row, column, cellValue) {
      return cellValue ? this.$timestampTostr(cellValue) : "";
    },
    // 加载数据
    async loadData() {
      const OrganData = await this.$store.dispatch(
        "jjyl/getAllliveServiceOrgan",
        {}
      );
      if (OrganData.success) {
        this.OrganList = OrganData.result;
      }
    },
    // 查看
    View(row) {
      this.setformType("查看");
      this.loadform(row);
    },
    // 编辑
    Edit(row) {
      this.setformType("审核");
      this.loadform(row);
    },
    // 设置表单状态
    setformType(title) {
      this.dialogFormVisible = true;
      this.dailogTitle = title;
    },
    loadform(row) {
      const rowinfo = Object.assign({}, row);
      this.$store
        .dispatch("jjyl/getDataInfo", rowinfo)
        .then((response) => {
          if (response.success) {
            this.formData = Object.assign(rowinfo, response.result);
            this.formData.collapseNames = this.formData.workItemList.map(
              (t) => t.itemName
            );
          } else {
            this.$message.error(response.message);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    // 弹窗关闭前处理
    handleClose() {
      this.resetForm();
    },
    // 提交
    submitForm(status) {
      this.loading = true;
      const copyForm = {
        id: this.formData.orderId,
        status: status,
        contractId: this.formData.contractId,
      };
      this.$store
        .dispatch("jjyl/workOrdeStartOrPauser", copyForm)
        .then((response) => {
          if (response.success) {
            this.$message.success("操作成功");
            this.dataRefresh();
          } else {
            this.$message.error(response.message);
          }
          this.dialogFormVisible = false;
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          console.log(error);
        });
    },
    // 重置
    resetForm() {
      const th = this;
      Object.keys(this.formData).forEach(function (key) {
        th.formData[key] = "";
      });
    },
    // 导出
    async Download() {
      this.loading = true;
      const downloadFrom = Object.assign({}, this.form);
      this.$store
        .dispatch("jjyl/downloadliveWorkOrder", downloadFrom)
        .then((response) => {
          let blob = new Blob([response], {
            type: "application/vnd.ms-excel",
          });
          let fileName = "工单管理" + ".xlsx";
          if (window.navigator.msSaveOrOpenBlob) {
            navigator.msSaveBlob(blob, fileName);
          } else {
            let link = document.createElement("a");

            link.href = window.URL.createObjectURL(blob);
            link.download = fileName;
            link.click();
            // 释放内存
            window.URL.revokeObjectURL(link.href);
          }
          this.loading = false;
          this.$message.success("导出成功");
        });
    },

    // 获取选中项
    getSelectedRows(rows) {
      this.TableOptions.selectedRows = rows;
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/styles/tabletoptool.scss";
</style>
