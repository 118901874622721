import * as sysModule from "@/api/sysModule";
const actions = {
  saveData(key, parm) {
    return new Promise((resolve, reject) => {
      sysModule
        .saveData(parm)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(key, error);;
        });
    });
  },
  moduleTree(key, parm) {
    return new Promise((resolve, reject) => {
      sysModule
        .moduleTree(parm)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(key, error);;
        });
    });
  },
  deleteData(key, parm) {
    return new Promise((resolve, reject) => {
      sysModule
        .deleteData(parm)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(key, error);;
        });
    });
  },
};

export default {
  namespaced: true,
  actions,
};
