import request from "@/utils/request";
// 家庭养老床位管理

/**
 * 服务组织
 * @param {http://192.168.3.247:3000/project/2623/interface/api/cat_7506} data
 * @returns
 */
// 分页列表
export function getbedServiceOrganList(data) {
  return request({
    url: "/api/bedServiceOrgan/getDataList.json",
    method: "post",
    data,
  });
}
// 列表数据
export function getAllbedServiceOrgan(data) {
  return request({
    url: "/api/bedServiceOrgan/getAllData.json",
    method: "post",
    data,
  });
}
// 新增/修改
export function savebedServiceOrgan(data) {
  return request({
    url: "/api/bedServiceOrgan/saveData.json",
    method: "post",
    data,
  });
}
// 删除
export function delbedServiceOrgan(data) {
  return request({
    url: "/api/bedServiceOrgan/deleteData",
    method: "post",
    data,
  });
}
// 导出
export function downloadbedServiceOrgan(data) {
  return request({
    url: "/api/bedServiceOrgan/exportData",
    responseType: "blob",
    method: "post",
    data,
  });
}

/**
 * 服务人员
 * @param {http://192.168.3.247:3000/project/2623/interface/api/cat_7527} data
 * @returns
 */
// 分页列表
export function getbedServicePersonList(data) {
  return request({
    url: "/api/bedServicePerson/getDataList.json",
    method: "post",
    data,
  });
}
// 新增/修改
export function savebedServicePerson(data) {
  return request({
    url: "/api/bedServicePerson/saveData.json",
    method: "post",
    data,
  });
}
// 删除
export function delbedServicePerson(data) {
  return request({
    url: "/api/bedServicePerson/deleteData.json",
    method: "post",
    data,
  });
}
// 导出
export function downloadbedServicePerson(data) {
  return request({
    url: "/api/bedServicePerson/exportData",
    responseType: "blob",
    method: "post",
    data,
  });
}

/**
 * 家庭-服务批次
 * @param {http://192.168.3.247:3000/project/2623/interface/api/cat_7513} data
 * @returns
 */
// 分页列表
export function getbedServiceBatchList(data) {
  return request({
    url: "/api/bedServiceBatch/getDataList.json",
    method: "post",
    data,
  });
}
// 列表数据
export function getAllbedServiceBatch(data) {
  return request({
    url: "/api/bedServiceBatch/getAllData.json",
    method: "post",
    data,
  });
}
// 新增/修改
export function savebedServiceBatch(data) {
  return request({
    url: "/api/bedServiceBatch/saveData.json",
    method: "post",
    data,
  });
}
// 删除
export function delbedServiceBatch(data) {
  return request({
    url: "/api/bedServiceBatch/deleteData.json",
    method: "post",
    data,
  });
}
// 分配
export function saveBatchRel(data) {
  return request({
    url: "/api/bedServiceBatch/saveBatchRel.json",
    method: "post",
    data,
  });
}

/**
 * 服务项目
 * @param {http://192.168.3.247:3000/project/2623/interface/api/cat_7464} data
 * @returns
 */
// 分页列表
export function getbedServiceItemList(data) {
  return request({
    url: "/api/bedServiceItem/getDataList.json",
    method: "post",
    data,
  });
}
// 新增/修改
export function savebedServiceItem(data) {
  return request({
    url: "/api/bedServiceItem/saveData.json",
    method: "post",
    data,
  });
}
// 删除
export function delbedServiceItem(data) {
  return request({
    url: "/api/bedServiceItem/deleteData.json",
    method: "post",
    data,
  });
}
// 列表数据
export function getAllbedServiceItem(data) {
  return request({
    url: "/api/bedServiceItem/getAllData.json",
    method: "post",
    data,
  });
}

/**
 * 居家-合约管理
 * @param {http://192.168.3.247:3000/project/2623/interface/api/cat_7478} data
 * @returns
 */
// 分页列表
export function getbedContractList(data) {
  return request({
    url: "/api/bedContract/getDataList.json",
    method: "post",
    data,
  });
}
// 签约/修改
export function savebedContract(data) {
  return request({
    url: "/api/bedContract/saveData.json",
    method: "post",
    data,
  });
}
// 获取批次下的项目
export function getItemByBathId(data) {
  return request({
    url: "/api/bedServiceBatch/getItemByBathId.json",
    method: "post",
    params: data,
  });
}
// 获取批次下的组织
export function getOrganByBathId(data) {
  return request({
    url: "/api/bedServiceBatch/getOrganByBathId.json",
    method: "post",
    params: data,
  });
}

/**
 * 居家-服务对象分配
 * @param {http://192.168.3.247:3000/project/2623/interface/api/cat_7485} data
 * @returns
 */
// 分页列表
export function getPersonList(data) {
  return request({
    url: "/api/bedContract/getPersonList.json",
    method: "post",
    data,
  });
}
// 获取服务组织下的人员
export function getDataByOrganId(data) {
  return request({
    url: "/api/bedServicePerson/getDataByOrganId.json",
    method: "post",
    params: data,
  });
}
// 分配人员/服务策略
export function savePerson(data) {
  return request({
    url: "/api/bedContract/savePerson.json",
    method: "post",
    data,
  });
}
/**
 * 居家-居家服务工单
 * @param {http://192.168.3.247:3000/project/2623/interface/api/cat_7492} data
 * @returns
 */

// PC-工单管理/生成工单
export function addWorkOrder(data) {
  return request({
    url: "/api/bedContract/addWorkOrder.json",
    method: "post",
    data,
  });
}
// PC-工单审核分页列表
export function getbedWorkOrderList(data) {
  return request({
    url: "/api/bedWorkOrder/getDataList.json",
    method: "post",
    data,
  });
}
// PC-工单审核列表导出
export function downloadbedWorkOrder(data) {
  return request({
    url: "/api/bedWorkOrder/exportData.json",
    responseType: "blob",
    method: "post",
    data,
  });
}
// PC-工单详情
export function getDataInfo(data) {
  return request({
    url: "/api/bedWorkOrder/getDataInfo.json",
    method: "post",
    data,
  });
}
// PC-工单审核
export function workOrdeStartOrPauser(data) {
  return request({
    url: "/api/bedWorkOrder/workOrdeStartOrPauser.json",
    method: "post",
    data,
  });
}
// PC-结算管理分页
export function getLiveDataList(data) {
  return request({
    url: "/api/bedContract/getLiveDataList.json",
    method: "post",
    data,
  });
}
/**
 * 智能设备
 * @param {http://192.168.3.247:3000/project/2623/interface/api/cat_7569} data
 * @returns
 */
// 分页列表
export function geteqElderlyList(data) {
  return request({
    url: "/api/eqElderly/getDataList.json",
    method: "post",
    data,
  });
}
// 监测数据
export function geteqElderlyData(data) {
  return request({
    url: "/api/eqElderlyData/getDataList.json",
    method: "post",
    data,
  });
}
