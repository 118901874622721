<template>
  <div
    v-loading="loading"
    element-loading-text="加载中"
    element-loading-spinner="el-icon-loading"
    element-loading-background="rgba(0, 0, 0, 0.8)"
    class="hl-full-container flexVertical"
  >
    <!-- 表单s -->
    <el-drawer
      :title="dailogTitle"
      :visible.sync="dialogFormVisible"
      direction="rtl"
      size="100%"
      :modal="false"
      :destroy-on-close="true"
      class="el-drawer-hl"
      @close="handleClose"
    >
      <el-form
        ref="elForm"
        :model="formData"
        :rules="rules"
        size="medium"
        class="padding15"
        label-width="120px"
      >
        <el-col :span="24">
          <el-row>
            <el-col :span="12">
              <el-form-item label="服务人员" prop="personId">
                <el-select
                  v-model="formData.personId"
                  filterable
                  clearable
                  :style="{ width: '100%' }"
                  placeholder="请选择服务人员"
                >
                  <el-option
                    v-for="item in servicepersonList"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                  >
                    <span style="float: left">{{ item.name }}</span>
                    <span
                      style="float: right; color: #8492a6; font-size: 13px"
                      >{{ item.phone }}</span
                    >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
        </el-col>

        <el-col :span="24">
          <el-row>
            <el-form-item size="large" style="text-align: center">
              <el-button
                class="qconfirm"
                type="primary"
                size="medium"
                @click="submitForm()"
                >提交</el-button
              >
            </el-form-item>
          </el-row>
        </el-col>
      </el-form>
    </el-drawer>

    <div class="search">
      <el-form label-width="80px" :model="form" ref="form" inline>
        <el-form-item label="网格：">
          <el-cascader
            size="small"
            style="flex: 1"
            :options="areaData"
            v-model="areaCode"
            placeholder="请选择网格编码"
            clearable
            :props="{ value: 'code', label: 'name', checkStrictly: true }"
            :show-all-levels="false"
            @change="cascaderChanged"
          >
          </el-cascader>
        </el-form-item>
        <el-form-item label="老人姓名">
          <el-input
            size="small"
            clearable
            v-model="form.name"
            placeholder="请输入老人姓名"
          ></el-input>
        </el-form-item>
        <el-form-item label="身份证号">
          <el-input
            size="small"
            clearable
            v-model="form.idCard"
            placeholder="请输入姓名"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button
            size="small"
            type="primary"
            icon="el-icon-search"
            @click="dataRefresh"
          >
            查询
          </el-button>
        </el-form-item>
      </el-form>
    </div>

    <div class="toolbar">
      <el-button
        type="primary"
        plain
        size="mini"
        icon="el-icon-plus"
        @click="Save"
        >分配
      </el-button>
    </div>
    <hltable
      :isNumber="false"
      :columnEdit="TableOptions.columnEdit"
      :formSearch="form"
      :refreshFlag="TableOptions.refreshFlag"
      :tableColumn="TableOptions.column"
      :selectedRows="TableOptions.selectedRows"
      :apiurl="TableOptions.apiurl"
      @getSelectedRows="getSelectedRows"
    >
      <div slot-scope="selected" slot="EditColumn">
        <el-tooltip effect="dark" content="查看" placement="top">
          <el-button
            type="warning"
            size="mini"
            icon="el-icon-search"
            circle
            @click="View(selected.singlerow)"
          ></el-button>
        </el-tooltip>
        <el-tooltip effect="dark" content="编辑" placement="top">
          <el-button
            type="primary"
            icon="el-icon-edit"
            size="mini"
            circle
            @click="Edit(selected.singlerow)"
          ></el-button>
        </el-tooltip>
      </div>
    </hltable>
  </div>
</template>

<script>
import { hltable } from "@/components";
export default {
  name: "fwdxfp",
  components: { hltable },
  data() {
    return {
      loading: false,
      token: { token: localStorage.getItem("systoken") },
      // 表单内容
      dailogTitle: "分配服务人员",
      dialogFormVisible: false,
      //功能树-s
      areaCode: [],
      areaData: [],
      // 查询条件
      form: {
        name: "",
        idCard: "",
        areaCode: "",
      },
      // 新增/修改 表单
      formData: {
        id: "",
        personId: "",
      },
      // 表单规则
      rules: {
        personId: [
          {
            required: true,
            message: "请选择服务人员",
            trigger: "change",
          },
        ],
      },
      // 列表设置
      TableOptions: {
        selectedRows: [],
        apiurl: "jjyl/getPersonList",
        refreshFlag: "",
        columnEdit: false,
        column: [
          {
            label: "老人姓名",
            prop: "name",
            checked: true,
          },
          {
            label: "身份证号",
            prop: "idCard",
            checked: true,
          },
          {
            label: "能力等级",
            prop: "level",
            checked: false,
          },
          {
            label: "老人类别",
            prop: "oldType",
            checked: false,
          },
          {
            label: "合约号",
            prop: "code",
            checked: true,
          },
          {
            label: "合约状态",
            prop: "status",
            checked: true,
          },
          {
            label: "服务批次",
            prop: "batch",
            checked: true,
          },
          {
            label: "服务组织",
            prop: "organ",
            checked: true,
          },
          {
            label: "服务人员",
            prop: "person",
            checked: true,
          },
        ],
      },
      servicepersonList: [],
    };
  },
  mounted() {
    this.dataRefresh();
    this.getAreaTree();
  },
  methods: {
    // 查询
    dataRefresh() {
      this.TableOptions.refreshFlag = new Date().getTime().toString();
    },
    // 获取网格信息
    getAreaTree() {
      this.$store
        .dispatch("api/getTree", {
          areaCode: this.$store.getters.user.areaCode,
        })
        .then((response) => {
          if (response.success) {
            this.areaData = response.result;
          } else {
            this.$message.error(response.message);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    // 时间格式化
    timeFormatter(row, column, cellValue) {
      return cellValue ? this.$timestampTostr(cellValue) : "";
    },
    // 分配
    async Save() {
      const pData = this.TableOptions.selectedRows;
      if (pData.length === 0) {
        this.$message.error("请选择需要分配的老人");
        return;
      }
      var status = false;
      pData.forEach((p) => {
        if (p.status !== "待分配服务人员") {
          status = true;
        }
      });
      if (status) {
        this.$message.error("请选择待分配服务人员的老人");
        return;
      }
      const p0Id = pData[0].organId;
      const x = pData.find((p) => p.organId !== p0Id);
      if (x) {
        this.$message.error("请选择相同组织下的老人");
        return;
      }
      this.loading = true;
      const ServicePersonData = await this.$store.dispatch(
        "jjyl/getDataByOrganId",
        {
          organId: p0Id,
        }
      );
      if (ServicePersonData.success) {
        this.servicepersonList = ServicePersonData.result;
      }
      this.formData.id = pData.map((a) => a.id).join(",");
      this.dialogFormVisible = true;

      this.loading = false;
    },
    // 弹窗关闭前处理
    handleClose() {
      this.formData = {
        id: "",
        personId: "",
      };
      this.servicepersonList = [];
    },
    // 提交
    submitForm() {
      this.$refs["elForm"].validate((valid) => {
        if (valid) {
          this.loading = true;
          const copyForm = Object.assign({}, this.formData);

          this.$store
            .dispatch("jjyl/savePerson", copyForm)
            .then((response) => {
              if (response.success) {
                this.$message.success("操作成功");
                this.dataRefresh();
              } else {
                this.$message.error(response.message);
              }
              this.dialogFormVisible = false;
              this.loading = false;
            })
            .catch((error) => {
              this.loading = false;
              console.log(error);
            });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    // 获取选中项
    getSelectedRows(rows) {
      this.TableOptions.selectedRows = rows;
    },
    cascaderChanged() {
      var [...village] = this.areaCode;
      this.form.areaCode = village.pop() || "";
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/styles/tabletoptool.scss";
</style>
