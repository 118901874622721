import * as sysUser from "@/api/sysUser";
const actions = {
  saveData(key, parm) {
    return new Promise((resolve, reject) => {
      sysUser
        .saveData(parm)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(key, error);
        });
    });
  },
  getDataList(key, parm) {
    return new Promise((resolve, reject) => {
      sysUser
        .getDataList(parm)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(key, error);
        });
    });
  },
  deleteData(key, parm) {
    return new Promise((resolve, reject) => {
      sysUser
        .deleteData(parm)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(key, error);
        });
    });
  },
};
export default {
  namespaced: true,
  actions,
};
