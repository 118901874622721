<template>
  <div class="hl-full-container flexVertical">
    <!-- 表单s -->
    <el-drawer
      :title="dailogTitle"
      :visible.sync="dialogFormVisible"
      direction="rtl"
      size="100%"
      :modal="false"
      :destroy-on-close="true"
      class="el-drawer-hl"
      @close="handleClose"
    >
      <el-form
        ref="elForm"
        :model="formData"
        :disabled="submitType == 'view' ? true : false"
        :rules="rules"
        size="medium"
        class="padding15"
        label-width="100px"
      >
        <el-col :span="24">
          <el-row>
            <el-col :span="24">
              <el-form-item label="名称" prop="name">
                <el-input
                  v-model="formData.name"
                  placeholder="请输入名称"
                  clearable
                  :style="{ width: '100%' }"
                >
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-col>
        <el-col :span="24">
          <el-row>
            <el-col :span="12">
              <el-form-item label="经度" prop="longitude">
                <el-input
                  v-model="formData.longitude"
                  placeholder="请输入经度"
                  clearable
                  :style="{ width: '100%' }"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="纬度" prop="latitude">
                <el-input
                  v-model="formData.latitude"
                  placeholder="请输入纬度"
                  clearable
                  :style="{ width: '100%' }"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-col>
        <el-col :span="24">
          <el-row>
            <el-col :span="12">
              <el-form-item label="网格绑定" prop="areaCode">
                <el-collapse v-model="collapseModel">
                  <el-collapse-item title="网格信息" name="网格信息">
                    <el-tree
                      :data="areaData"
                      show-checkbox
                      node-key="code"
                      ref="tree"
                      :default-expanded-keys="defaultExpandedKeys"
                      :check-strictly="true"
                      highlight-current
                      @check="handleCheckChange"
                      :props="defaultProps"
                    >
                    </el-tree>
                  </el-collapse-item>
                </el-collapse>
              </el-form-item>
            </el-col>
          </el-row>
        </el-col>
        <el-col :span="24">
          <el-row>
            <el-form-item size="large" style="text-align: center">
              <el-button
                v-show="submitType != 'view' ? true : false"
                class="qconfirm"
                type="primary"
                size="medium"
                @click="submitForm()"
                >提交</el-button
              >
              <el-button
                v-show="submitType != 'view' ? true : false"
                class="qcancle"
                size="medium"
                @click="resetForm()"
              >
                重置
              </el-button>
            </el-form-item>
          </el-row>
        </el-col>
      </el-form>
    </el-drawer>
    <!-- 表单e -->
    <div class="search">
      <el-form :model="form" ref="form" label-width="80px" inline>
        <el-form-item label="名称">
          <el-input v-model="form.name" placeholder="请输入名称"></el-input>
        </el-form-item>
        <el-form-item label="网格：">
          <el-cascader
            style="flex: 1"
            :options="areaData"
            v-model="areaCode"
            placeholder="请选择网格编码"
            clearable
            :props="{ value: 'code', label: 'name', checkStrictly: true }"
            :show-all-levels="false"
            @change="cascaderChanged"
          >
          </el-cascader>
        </el-form-item>

        <el-form-item>
          <el-button
            size="small"
            type="primary"
            icon="el-icon-search"
            @click="dataRefresh"
            >查询</el-button
          >
          <el-button
            size="small"
            icon="el-icon-refresh-left"
            @click="reset('Form')"
            >重置</el-button
          >
        </el-form-item>
      </el-form>
    </div>
    <div class="toolbar">
      <el-button type="text" plain size="mini" icon="el-icon-upload2">
        <a href="/static/助餐站点.xlsx" download="助餐站点管理模板.xlsx"
          >模板下载</a
        ></el-button
      >
      <el-upload
        :headers="token"
        :action="xlsAreaAction"
        :show-file-list="false"
        :on-success="onXlsUploadSuccess"
        accept=".xlsx, .xls"
        style="margin: 0 0.7rem"
      >
        <el-button type="success" plain size="mini" icon="el-icon-upload"
          >导入</el-button
        >
      </el-upload>

      <el-button
        type="primary"
        plain
        size="mini"
        icon="el-icon-plus"
        @click="Add"
        >新增</el-button
      >
      <el-button
        type="danger"
        plain
        size="mini"
        icon="el-icon-delete"
        @click="Del"
        >删除</el-button
      >
    </div>
    <hltable
      :isNumber="false"
      :columnEdit="TableOptions.columnEdit"
      :formSearch="form"
      :refreshFlag="TableOptions.refreshFlag"
      :tableColumn="TableOptions.column"
      :selectedRows="TableOptions.selectedRows"
      :apiurl="TableOptions.apiurl"
      @getSelectedRows="getSelectedRows"
    >
      <div slot-scope="selected" slot="EditColumn">
        <el-button
          type="primary"
          icon="el-icon-edit"
          size="mini"
          circle
          @click="Edit(selected.singlerow)"
        ></el-button>
        <el-button
          type="warning"
          size="mini"
          icon="el-icon-search"
          circle
          @click="View(selected.singlerow)"
        ></el-button>
      </div>
    </hltable>
  </div>
</template>

<script>
import { hltable } from "@/components";
export default {
  name: "zdgl",
  components: { hltable },
  data() {
    return {
      token: { token: localStorage.getItem("systoken") },
      collapseModel: "网格信息",
      dailogTitle: "",
      dialogFormVisible: false,
      submitType: "",

      xlsAreaAction: "/api/mealSite/importData.json",
      //表单信息-s
      formData: {
        name: "",
        longitude: "",
        latitude: "",
      },
      //表单信息-e
      //功能树-s
      areaCode: [],
      areaData: [],
      // 默认展开
      defaultExpandedKeys: [],
      defaultProps: {
        children: "children",
        label: "name",
      },
      rules: {
        name: [
          {
            required: true,
            message: "请输入名称",
            trigger: "blur",
          },
        ],
        longitude: [
          {
            required: true,
            message: "请输入经度",
            trigger: "blur",
          },
        ],
        latitude: [
          {
            required: true,
            message: "请输入纬度",
            trigger: "blur",
          },
        ],
      },
      //表单信息-e
      form: {
        name: "",
        areaCode: "",
      },
      TableOptions: {
        selectedRows: [],
        apiurl: "api/getMealSiteList",
        refreshFlag: "",
        columnEdit: true,
        column: [
          {
            label: "名称",
            prop: "name",
            checked: true,
          },
          {
            label: "经度",
            prop: "longitude",
            checked: true,
          },
          {
            label: "纬度",
            prop: "latitude",
            checked: true,
          },
          {
            label: "地区",
            prop: "areaName",
            checked: true,
          },
        ],
      },
    };
  },
  watch: {},
  created() {},
  mounted() {
    this.dataRefresh();
    this.getAreaTree();
  },
  destroyed() {},
  methods: {
    getCheckedNodes() {
      const nodes = this.$refs.tree.getCheckedNodes(false, false);
      let areaCodeList = [];
      nodes.forEach((v) => {
        areaCodeList.push(v.code);
      });
      return areaCodeList.join(",");
    },
    async setCheckedNodes(areaCodes) {
      let areaCodeList = [];
      areaCodes.split(",").forEach((v) => {
        areaCodeList.push({ code: v });
      });
      this.$nextTick(() => {
        this.defaultExpandedKeys = areaCodeList;
        this.$refs.tree.setCheckedNodes(areaCodeList);
      });
    },
    handleCheckChange(data) {
      this.$refs.tree.setCurrentKey(data.code);
      this.$refs.tree.setCheckedKeys([data.code]);
    },
    getAreaTree() {
      this.$store
        .dispatch("api/getTree", {
          areaCode: this.$store.getters.user.areaCode,
        })
        .then((response) => {
          if (response.success) {
            this.areaData = response.result;
          } else {
            this.$message.error(response.message);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    dataRefresh() {
      this.form.areaCode =
        this.form.areaCode || this.$store.getters.user.areaCode;
      this.TableOptions.refreshFlag = new Date().getTime().toString();
    },
    handleClose() {
      this.resetForm();
    },
    resetForm() {
      const th = this;
      Object.keys(this.formData).forEach(function (key) {
        th.formData[key] = "";
      });
    },
    submitForm() {
      this.$refs["elForm"].validate((valid) => {
        if (valid) {
          this.loading = true;
          const copyForm = Object.assign({}, this.formData);
          if (this.submitType == "add") {
            copyForm.id = "";
          }
          copyForm.areaCode = this.getCheckedNodes();
          this.$store
            .dispatch("api/saveMealSite", copyForm)
            .then((response) => {
              if (response.success) {
                this.$message.success("操作成功");
                this.dataRefresh();
              } else {
                this.$message.error(response.message);
              }
              this.dialogFormVisible = false;
            })
            .catch((error) => {
              this.dialogFormVisible = false;
              console.log(error);
            });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    // 导入
    onXlsUploadSuccess(response) {
      if (response.success) {
        this.$message({
          type: "success",
          message: "导入成功",
        });
        this.dataRefresh();
      } else {
        this.$message({
          type: "error",
          message: "导入失败",
        });
      }
    },
    loadform(row) {
      const rowinfo = Object.assign({}, row);
      this.formData = rowinfo;
      if (this.formData.areaCode) {
        this.setCheckedNodes(this.formData.areaCode);
      }
    },
    setformType(type, title) {
      this.dialogFormVisible = true;
      this.dailogTitle = title;
      this.submitType = type;
    },
    Add() {
      this.setformType("add", "新增");
    },
    Edit(row) {
      this.setformType("edit", "编辑");
      this.loadform(row);
    },
    Del: function () {
      if (this.TableOptions.selectedRows.length == 0) {
        this.$message({
          type: "error",
          message: "请勾选下方表格中的数据",
        });
        return false;
      }
      var ids = [];
      this.TableOptions.selectedRows.forEach((key) => {
        ids.push(key.id);
      });
      this.$confirm("确定要进行删除吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.$store
          .dispatch("api/delMealSite", { ids: ids })
          .then((response) => {
            if (response.success) {
              this.$message.success("操作成功");
              this.dataRefresh();
            } else {
              this.$message.error(response.message);
            }
          })
          .catch((error) => {
            console.log(error);
          });
      });
    },
    View(row) {
      this.setformType("view", "查看");
      this.loadform(row);
    },
    getSelectedRows(rows) {
      this.TableOptions.selectedRows = rows;
    },
    reset() {
      // this.$refs['form'].resetFields()
      // 比上方获取demo的清理方式更彻底
      const th = this;
      Object.keys(this.form).forEach(function (key) {
        th.form[key] = "";
      });
    },
    cascaderChanged() {
      var [...village] = this.areaCode;
      this.form.areaCode = village.pop() || "";
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/styles/tabletoptool.scss";
</style>
