import request from '@/utils/request'

export function saveData(data) {
  return request({
    url: '/api/sysModule/saveData.json',
    method: 'post',
    data
  })
}

export function moduleTree(data) {
  return request({
    url: '/api/sysModule/moduleTree.json',
    method: 'post',
    data
  })
}

export function deleteData(data) {
  return request({
    url: '/api/sysModule/deleteData.json',
    method: 'post',
    data
  })
}