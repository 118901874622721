<template>
  <div class="errPage-container" style="background: #edeef4;width: 100vw;
    height: 100vh;
    margin: 0;">
    <el-row style="    width: 720px;
    margin: 0 auto;
    padding-top: 10%;">
      <el-col :span="12">
        <h1 class="text-jumbo text-ginormous">
          尊敬的用户,您好
        </h1>
        <h2>您访问页面无效</h2>
        <h6>您可以执行以下操作</h6>
        <ul class="list-unstyled"> 
          <li class="link-type">
            <router-link to="/">
              自动进入系统
            </router-link>
          </li>
        </ul>
      </el-col>
      <el-col :span="12">
        <img :src="errGif" width="313" height="428" alt="图片加载失败">
      </el-col>
    </el-row> 
  </div>
</template>

<script>
import errGif from '@/assets/404_images/404.gif'

export default {
  name: 'Page404',
  data() {
    return {
      errGif: errGif + '?' + +new Date(), 
      dialogVisible: false
    }
  },
  methods: {
  }
}
</script>

<style lang="scss" scoped>
  .errPage-container {
    width: 800px;
    max-width: 100%;
    margin: 100px auto;
    .pan-back-btn {
      background: #008489;
      color: #fff;
      border: none!important;
    }
    .pan-gif {
      margin: 0 auto;
      display: block;
    }
    .pan-img {
      display: block;
      margin: 0 auto;
      width: 100%;
    }
    .text-jumbo {
      font-size: 35px;
      font-weight: 700;
      color: #484848;
    }
    .list-unstyled {
      font-size: 14px;
      li {
        padding-bottom: 5px;
      }
      a {
        color: #008489;
        text-decoration: none;
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
</style>
