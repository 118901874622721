<template>
  <div class="hl-full-container flexVertical">
    <div class="search">
      <el-form :model="form" ref="form" label-width="80px" inline>
        <el-form-item label="名称">
          <el-input v-model="form.name" placeholder="请输入名称"></el-input>
        </el-form-item>
        <el-form-item label="网格：">
          <el-cascader
            style="flex: 1"
            :options="areaData"
            v-model="areaCode"
            placeholder="请选择网格编码"
            clearable
            :props="{ value: 'code', label: 'name', checkStrictly: true }"
            :show-all-levels="false"
            @change="cascaderChanged"
          >
          </el-cascader>
        </el-form-item>

        <el-form-item>
          <el-button
            size="small"
            type="primary"
            icon="el-icon-search"
            @click="dataRefresh"
            >查询</el-button
          >
          <el-button
            size="small"
            icon="el-icon-refresh-left"
            @click="reset('Form')"
            >重置</el-button
          >
        </el-form-item>
      </el-form>
    </div>
    <hltable
      :isNumber="false"
      :columnEdit="TableOptions.columnEdit"
      :formSearch="form"
      :refreshFlag="TableOptions.refreshFlag"
      :tableColumn="TableOptions.column"
      :selectedRows="TableOptions.selectedRows"
      :apiurl="TableOptions.apiurl"
      @getSelectedRows="getSelectedRows"
    >
    </hltable>
  </div>
</template>

<script>
import { hltable } from "@/components";
export default {
  name: 'zdbd',
  components: { hltable },
  data () {
    return {
      areaData: [],
      areaCode: [],
      form: {
        name: "",
        sex: "",
        idCard: "",
        areaCode: "",
      },
      TableOptions: {
        selectedRows: [],
        apiurl: "api/getElderlyInfoList",
        refreshFlag: "",
        columnEdit: true,
        column: [
          {
            label: "姓名",
            prop: "name",
            checked: true,
          },
          {
            label: "年龄",
            prop: "age",
            checked: true,
          },
          {
            label: "联系方式",
            prop: "phone",
            checked: true,
          },
          {
            label: "身份证号",
            prop: "idCard",
            checked: true,
          },
          {
            label: "能力等级",
            prop: "level",
            checked: true,
          },
          {
            label: "老人类别",
            prop: "oldType",
            checked: true,
          },
          {
            label: "地区",
            prop: "areaName",
            checked: true,
          },
          {
            label: "余额",
            prop: "balance",
            checked: true,
          },
        ],
      },
    }
  },
  methods: {
    
    getSelectedRows(){

    },
    // 搜索框
    cascaderChanged() {
      var [...village] = this.areaCode;
      this.form.areaCode = village.pop() || "";
    },
    // 查询
    dataRefresh() {
      
    },
  }
}
</script>

<style scoped lang="scss">
  
</style>