<template>
  <div>
    <div class="hl-header">
      <i @click="toggleSideBar" class="el-icon-s-operation sj" />
      <el-dropdown trigger="click">
        <span class="el-dropdown-link">
          <i style="color: #ff8754" class="el-icon-user-solid" />
          <span class="hl-userName">{{ userName }}</span>
          <i class="el-icon-arrow-down"></i>
        </span>
        <el-dropdown-menu slot="dropdown">
          <router-link to="/home/personal">
            <el-dropdown-item>个人设置</el-dropdown-item>
          </router-link>
          <el-dropdown-item divided @click.native="loginOut()">
            退出系统
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
  </div>
</template>

<script>
export default {
  name: "Header",
  components: {},
  data() {
    return {};
  },
  computed: {
    userName() {
      return this.$store.getters.user.userName;
    },
  },
  watch: {},
  created() {},
  mounted() {},
  destroyed() {},
  methods: {
    async loginOut() {
      this.$confirm("尊敬的用户您好,即将退出系统, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "info",
      })
        .then(() => {
          this.$store.dispatch("user/logout");
          this.$store.dispatch("tagsView/delAllViews");
          this.$router.push(`/login`);
        })
        .catch(() => {});
    },
    toggleSideBar() {
      this.$store.dispatch("app/toggleSideBar");
      this.icoISshow = !this.icoISshow;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/theme.scss";
.el-dropdown {
  height: 30px;
  line-height: 23px;
  font-size: 20px;
  color: $headerTextColor;
}
.el-dropdown-link {
  display: flex !important;
  flex-direction: row !important;
  align-items: center !important;
}

.sj {
  font-size: 30px;
  color: #ff8754;
  cursor: pointer;
}
</style>
