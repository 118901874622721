<template>
  <div
    v-loading="loading"
    element-loading-text="加载中"
    element-loading-spinner="el-icon-loading"
    element-loading-background="rgba(0, 0, 0, 0.8)"
    class="hl-full-container flexVertical"
  >
    <!-- 表单s -->
    <el-drawer
      :title="dailogTitle"
      :visible.sync="dialogFormVisible"
      direction="rtl"
      size="100%"
      :modal="false"
      :destroy-on-close="true"
      class="el-drawer-hl"
      @close="handleClose"
    >
      <el-form
        ref="elForm"
        :model="formData"
        :disabled="submitType == 'view' ? true : false"
        :rules="rules"
        size="medium"
        class="padding15"
        label-width="120px"
      >
        <el-col :span="24">
          <el-row>
            <el-col :span="12">
              <el-form-item label="机构名称" prop="name">
                <el-input
                  v-model="formData.name"
                  placeholder="请输入机构名称"
                  clearable
                  :style="{ width: '100%' }"
                >
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="详细地址" prop="detailAddress">
                <el-input
                  v-model="formData.detailAddress"
                  placeholder="请输入详细地址"
                  clearable
                  :style="{ width: '100%' }"
                >
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="联系地址" prop="contactAddress">
                <el-input
                  v-model="formData.contactAddress"
                  placeholder="请输入联系地址"
                  clearable
                  type="textarea"
                  :rows="5"
                  :style="{ width: '100%' }"
                >
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="活动内容" prop="activityContent">
                <el-input
                  v-model="formData.activityContent"
                  placeholder="请输入活动内容"
                  clearable
                  type="textarea"
                  :rows="5"
                  :style="{ width: '100%' }"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-col>

        <el-col :span="24">
          <el-row>
            <el-form-item size="large" style="text-align: center">
              <el-button
                v-show="submitType != 'view' ? true : false"
                class="qconfirm"
                type="primary"
                size="medium"
                @click="submitForm()"
                >提交</el-button
              >
              <el-button
                v-show="submitType != 'view' ? true : false"
                class="qcancle"
                size="medium"
                @click="resetForm()"
              >
                重置
              </el-button>
            </el-form-item>
          </el-row>
        </el-col>
      </el-form>
    </el-drawer>

    <div class="search">
      <el-form label-width="100px" :model="form" ref="form" inline>
        <el-form-item label="机构名称">
          <el-input
            size="small"
            clearable
            v-model="form.name"
            placeholder="请输入机构名称"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button
            size="small"
            type="primary"
            icon="el-icon-search"
            @click="dataRefresh"
            >查询</el-button
          >
        </el-form-item>
      </el-form>
    </div>
    <div class="toolbar">
      <el-button
        type="primary"
        plain
        size="mini"
        icon="el-icon-plus"
        @click="Add"
        >新增</el-button
      >
      <el-button
        type="danger"
        plain
        size="mini"
        icon="el-icon-delete"
        @click="Del"
        >删除</el-button
      >
    </div>
    <hltable
      :isNumber="false"
      :columnEdit="TableOptions.columnEdit"
      :formSearch="form"
      :refreshFlag="TableOptions.refreshFlag"
      :tableColumn="TableOptions.column"
      :selectedRows="TableOptions.selectedRows"
      :apiurl="TableOptions.apiurl"
      @getSelectedRows="getSelectedRows"
    >
      <div slot-scope="selected" slot="EditColumn">
        <el-tooltip effect="dark" content="查看" placement="top">
          <el-button
            type="warning"
            size="mini"
            icon="el-icon-search"
            circle
            @click="View(selected.singlerow)"
          ></el-button>
        </el-tooltip>
        <el-tooltip effect="dark" content="编辑" placement="top">
          <el-button
            type="primary"
            icon="el-icon-edit"
            size="mini"
            circle
            @click="Edit(selected.singlerow)"
          ></el-button>
        </el-tooltip>
      </div>
    </hltable>
  </div>
</template>

<script>
import { hltable } from "@/components";
export default {
  name: "fwrygl",
  components: { hltable },
  data() {
    return {
      loading: false,
      token: { token: localStorage.getItem("systoken") },
      // 表单内容
      dailogTitle: "",
      dialogFormVisible: false,
      submitType: "",
      // 查询条件
      form: {
        name: "",
      },
      // 新增/修改 表单
      formData: {
        name: "",
        detailAddress: "",
        contactAddress: "",
        activityContent: "",
      },
      // 表单规则
      rules: {
        name: [
          {
            required: true,
            message: "请输入机构名称",
            trigger: "blur",
          },
        ],
        detailAddress: [
          {
            required: true,
            message: "请输入详细地址",
            trigger: "blur",
          },
        ],
        contactAddress: [
          {
            required: true,
            message: "请输入联系地址",
            trigger: "blur",
          },
        ],
        activityContent: [
          {
            required: true,
            message: "请输入活动内容",
            trigger: "blur",
          },
        ],
      },
      // 列表设置
      TableOptions: {
        selectedRows: [],
        apiurl: "api/getliveFacilityManagementList",
        refreshFlag: "",
        columnEdit: true,
        column: [
          {
            label: "机构名称",
            prop: "name",
            checked: true,
          },
          {
            label: "详细地址",
            prop: "detailAddress",
            checked: true,
          },
          {
            label: "联系地址",
            prop: "contactAddress",
            checked: true,
          },
          {
            label: "活动内容",
            prop: "activityContent",
            checked: true,
          },
        ],
      },
    };
  },
  async mounted() {
    await this.loadData();
    this.dataRefresh();
  },
  methods: {
    // 查询
    dataRefresh() {
      this.TableOptions.refreshFlag = new Date().getTime().toString();
    },
    // 加载数据
    async loadData() {},
    // 新增
    Add() {
      this.setformType("add", "新增");
    },
    // 查看
    View(row) {
      this.setformType("view", "查看");
      this.loadform(row);
    },
    // 编辑
    Edit(row) {
      this.setformType("edit", "编辑");
      this.loadform(row);
    },
    // 设置表单状态
    setformType(type, title) {
      this.dialogFormVisible = true;
      this.dailogTitle = title;
      this.submitType = type;
    },
    loadform(row) {
      const rowinfo = Object.assign({}, row);
      this.formData = rowinfo;
    },
    // 弹窗关闭前处理
    handleClose() {
      this.resetForm();
    },
    // 删除
    Del() {
      if (this.TableOptions.selectedRows.length == 0) {
        this.$message({
          type: "error",
          message: "请勾选下方表格中的数据",
        });
        return false;
      }
      var ids = [];
      this.TableOptions.selectedRows.forEach((key) => {
        ids.push(key.id);
      });
      this.$confirm("确定要进行删除吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.$store
          .dispatch("api/delliveFacilityManagement", { ids: ids })
          .then((response) => {
            if (response.success) {
              this.$message.success("操作成功");
              this.dataRefresh();
            } else {
              this.$message.error(response.message);
            }
          })
          .catch((error) => {
            console.log(error);
          });
      });
    },
    // 提交
    submitForm() {
      this.$refs["elForm"].validate((valid) => {
        if (valid) {
          this.loading = true;
          const copyForm = Object.assign({}, this.formData);
          if (this.submitType == "add") {
            copyForm.id = "";
          }
          this.$store
            .dispatch("api/saveliveFacilityManagement", copyForm)
            .then((response) => {
              if (response.success) {
                this.$message.success("操作成功");
                this.dataRefresh();
              } else {
                this.$message.error(response.message);
              }
              this.dialogFormVisible = false;
              this.loading = false;
            })
            .catch((error) => {
              this.loading = false;
              console.log(error);
            });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    // 重置
    resetForm() {
      const th = this;
      Object.keys(this.formData).forEach(function (key) {
        th.formData[key] = "";
      });
    },

    // 获取选中项
    getSelectedRows(rows) {
      this.TableOptions.selectedRows = rows;
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/styles/tabletoptool.scss";
</style>
