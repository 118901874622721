import * as api from "@/api/jjyl";
const actions = {
  /**
   * 服务组织
   * @param {http://192.168.3.247:3000/project/2623/interface/api/cat_7443} data
   * @returns
   */
  // 分页列表
  getliveServiceOrganList({}, parm) {
    return new Promise((resolve, reject) => {
      api
        .getliveServiceOrganList(parm)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  // 列表数据
  getAllliveServiceOrgan({}, parm) {
    return new Promise((resolve, reject) => {
      api
        .getAllliveServiceOrgan(parm)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  // 新增/修改
  saveliveServiceOrgan({}, parm) {
    return new Promise((resolve, reject) => {
      api
        .saveliveServiceOrgan(parm)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  // 删除
  delliveServiceOrgan({}, parm) {
    return new Promise((resolve, reject) => {
      api
        .delliveServiceOrgan(parm)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  // 导出
  downloadliveServiceOrgan({}, parm) {
    return new Promise((resolve, reject) => {
      api
        .downloadliveServiceOrgan(parm)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  /**
   * 服务人员
   * @param {http://192.168.3.247:3000/project/2623/interface/api/cat_7471} data
   * @returns
   */
  // 分页列表
  getliveServicePersonList({}, parm) {
    return new Promise((resolve, reject) => {
      api
        .getliveServicePersonList(parm)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  // 新增/修改
  saveliveServicePerson({}, parm) {
    return new Promise((resolve, reject) => {
      api
        .saveliveServicePerson(parm)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  // 删除
  delliveServicePerson({}, parm) {
    return new Promise((resolve, reject) => {
      api
        .delliveServicePerson(parm)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  // 导出
  downloadliveServicePerson({}, parm) {
    return new Promise((resolve, reject) => {
      api
        .downloadliveServicePerson(parm)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  /**
   * 服务批次包
   * @param {http://192.168.3.247:3000/project/2623/interface/api/cat_7457} data
   * @returns
   */
  // 分页列表
  getliveServiceBatchList({}, parm) {
    return new Promise((resolve, reject) => {
      api
        .getliveServiceBatchList(parm)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  // 列表数据
  getAllliveServiceBatch({}, parm) {
    return new Promise((resolve, reject) => {
      api
        .getAllliveServiceBatch(parm)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  // 分配
  saveBatchRel({}, parm) {
    return new Promise((resolve, reject) => {
      api
        .saveBatchRel(parm)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  // 新增/修改
  saveliveServiceBatch({}, parm) {
    return new Promise((resolve, reject) => {
      api
        .saveliveServiceBatch(parm)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  // 删除
  delliveServiceBatch({}, parm) {
    return new Promise((resolve, reject) => {
      api
        .delliveServiceBatch(parm)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  /**
   * 服务项目
   * @param {http://192.168.3.247:3000/project/2623/interface/api/cat_7464} data
   * @returns
   */
  // 分页列表
  getliveServiceItemList({}, parm) {
    return new Promise((resolve, reject) => {
      api
        .getliveServiceItemList(parm)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  // 新增/修改
  saveliveServiceItem({}, parm) {
    return new Promise((resolve, reject) => {
      api
        .saveliveServiceItem(parm)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  // 删除
  delliveServiceItem({}, parm) {
    return new Promise((resolve, reject) => {
      api
        .delliveServiceItem(parm)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  // 列表数据
  getAllliveServiceItem({}, parm) {
    return new Promise((resolve, reject) => {
      api
        .getAllliveServiceItem(parm)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  /**
   * 居家-合约管理
   * @param {http://192.168.3.247:3000/project/2623/interface/api/cat_7478} data
   * @returns
   */
  // 分页列表
  getliveContractList({}, parm) {
    return new Promise((resolve, reject) => {
      api
        .getliveContractList(parm)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  // 签约/修改
  saveliveContract({}, parm) {
    return new Promise((resolve, reject) => {
      api
        .saveliveContract(parm)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  // 获取批次下的项目
  getItemByBathId({}, parm) {
    return new Promise((resolve, reject) => {
      api
        .getItemByBathId(parm)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  // 获取批次下的组织
  getOrganByBathId({}, parm) {
    return new Promise((resolve, reject) => {
      api
        .getOrganByBathId(parm)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  /**
   * 居家-服务对象分配
   * @param {http://192.168.3.247:3000/project/2623/interface/api/cat_7485} data
   * @returns
   */
  // 分页列表
  getPersonList({}, parm) {
    return new Promise((resolve, reject) => {
      api
        .getPersonList(parm)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  // 获取服务组织下的人员
  getDataByOrganId({}, parm) {
    return new Promise((resolve, reject) => {
      api
        .getDataByOrganId(parm)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  // 分配人员/服务策略
  savePerson({}, parm) {
    return new Promise((resolve, reject) => {
      api
        .savePerson(parm)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  /**
   * 居家-居家服务工单
   * @param {http://192.168.3.247:3000/project/2623/interface/api/cat_7492} data
   * @returns
   */

  // PC-工单管理/生成工单
  addWorkOrder({}, parm) {
    return new Promise((resolve, reject) => {
      api
        .addWorkOrder(parm)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  // PC-工单审核分页列表
  getliveWorkOrderList({}, parm) {
    return new Promise((resolve, reject) => {
      api
        .getliveWorkOrderList(parm)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  // PC-工单审核列表导出
  downloadliveWorkOrder({}, parm) {
    return new Promise((resolve, reject) => {
      api
        .downloadliveWorkOrder(parm)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  // PC-工单详情
  getDataInfo({}, parm) {
    return new Promise((resolve, reject) => {
      api
        .getDataInfo(parm)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  // PC-工单审核
  workOrdeStartOrPauser({}, parm) {
    return new Promise((resolve, reject) => {
      api
        .workOrdeStartOrPauser(parm)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  // PC-结算管理分页
  getLiveDataList({}, parm) {
    return new Promise((resolve, reject) => {
      api
        .getLiveDataList(parm)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};
export default {
  namespaced: true,
  actions,
};
