<template>
  <div class="hl-full-container flexVertical">
    <div class="search">
      <el-form :model="form" ref="form" label-width="80px" inline>
        <el-form-item label="名称">
          <el-input v-model="form.name" placeholder="请输入名称"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button
            size="small"
            type="primary"
            icon="el-icon-search"
            @click="dataRefresh"
            >查询</el-button
          >
          <el-button
            size="small"
            icon="el-icon-refresh-left"
            @click="reset('Form')"
            >重置</el-button
          >
        </el-form-item>
      </el-form>
    </div>
    <div class="toolbar">
      <el-button
        type="primary"
        plain
        size="mini"
        icon="el-icon-plus"
        @click="Add"
        >新增</el-button
      >
      <el-button
        type="danger"
        plain
        size="mini"
        icon="el-icon-delete"
        @click="Del"
        >删除</el-button
      >
    </div>
    <hltable
      :isNumber="false"
      :columnEdit="TableOptions.columnEdit"
      :formSearch="form"
      :refreshFlag="TableOptions.refreshFlag"
      :tableColumn="TableOptions.column"
      :selectedRows="TableOptions.selectedRows"
      :apiurl="TableOptions.apiurl"
      @getSelectedRows="getSelectedRows"
    >
    </hltable>
  </div>
</template>

<script>
import { hltable } from "@/components";
export default {
  name: 'payingOut',
  components: { hltable },
  data () {
    return {
      form: {
        name: "",
        sex: "",
        idCard: "",
        areaCode: "",
      },
      TableOptions: {
        selectedRows: [],
        apiurl: "api/getElderlyInfoList",
        refreshFlag: "",
        columnEdit: true,
        column: [
          {
            label: "名称",
            prop: "name",
            checked: true,
          },
          {
            label: "县补贴标准",
            prop: "age",
            checked: true,
          },
          {
            label: "市补贴标准",
            prop: "phone",
            checked: true,
          },
          {
            label: "发放标准",
            prop: "idCard",
            checked: true,
          }
        ],
      },
    }
  },
  methods: {
    getSelectedRows(){

    },
    // 查询
    dataRefresh() {
      
    },
    Add() {
      
    },
    Del(){
      
    }
  }
}
</script>

<style scoped lang="scss">
  @import "@/styles/tabletoptool.scss";
</style>