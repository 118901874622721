import * as sysRole from "@/api/sysRole";
const actions = {
  saveData(key, parm) {
    return new Promise((resolve, reject) => {
      sysRole
        .saveData(parm)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(key, error);
        });
    });
  },
  getDataList(key, parm) {
    return new Promise((resolve, reject) => {
      sysRole
        .getDataList(parm)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(key, error);
        });
    });
  },
  getAllList() {
    return new Promise((resolve, reject) => {
      sysRole
        .getAllList()
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  getDataInfo(key, parm) {
    return new Promise((resolve, reject) => {
      sysRole
        .getDataInfo(parm)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(key, error);
        });
    });
  },
  deleteData(key, parm) {
    return new Promise((resolve, reject) => {
      sysRole
        .deleteData(parm)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(key, error);
        });
    });
  },
  roleSaveData(key, parm) {
    return new Promise((resolve, reject) => {
      sysRole
        .roleSaveData(parm)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(key, error);
        });
    });
  },
};

export default {
  namespaced: true,
  actions,
};
