<template>
  <div :class="classObj" class="hl-layout-wrapper-skc">
    <div class="hl-header-wrapper-skc">
      <div
        class="av-container w100 flex-r-c center"
        :style="{ width: !isClose ? '100%' : '60px' }"
      >
        <div v-if="!isClose">齐智养老</div>
        <div style="font-size: 18px" v-else>WUT</div>
      </div>
      <Sidebar></Sidebar>
    </div>
    <div
      class="hl-container-skc"
      :style="{ width: !isClose ? 'calc(100% - 210px)' : 'calc(100% - 60px)' }"
    >
      <HlHeader></HlHeader>
      <div class="hl-content">
        <TagsView></TagsView>
        <AppMain></AppMain>
      </div>
    </div>
  </div>
</template>

<script>
import { AppMain, Sidebar, TagsView, HlHeader } from "./components";
import { mapState } from "vuex";
export default {
  name: "Layout",
  components: {
    AppMain,
    Sidebar,
    TagsView,
    HlHeader,
  },
  computed: {
    ...mapState({
      sidebar: (state) => state.app.sidebar,
    }),
    classObj() {
      return {
        hideSidebar: this.sidebar.opened,
      };
    },
    isClose() {
      return this.sidebar.opened;
    },
  },
  methods: {},
  mounted() {},
};
</script>

<style lang="scss" scoped>
.hl-layout-wrapper-skc {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: row;
}

.hl-header-wrapper-skc {
  display: flex;
  flex-direction: column;
  background-size: 100% 100%;
}

.hl-container-skc {
  display: flex;
  height: 100%;
  flex-direction: column;
}

.av-container {
  height: 80px;
  background: #003a5b;
  font-weight: 700;
  font-size: 24px;
  color: #ffffff;
}
</style>
