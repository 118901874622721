<template>
  <div
    v-loading="loading"
    element-loading-text="加载中"
    element-loading-spinner="el-icon-loading"
    element-loading-background="rgba(0, 0, 0, 0.8)"
    class="hl-full-container flexVertical"
  >
    <div class="search">
      <el-form label-width="80px" :model="form" ref="form" inline>
        <el-form-item label="服务对象">
          <el-input
            size="small"
            clearable
            v-model="form.name"
            placeholder="请输入服务对象"
          ></el-input>
        </el-form-item>
        <el-form-item label="身份证号">
          <el-input
            size="small"
            clearable
            v-model="form.idCard"
            placeholder="请输入身份证号"
          ></el-input>
        </el-form-item>

        <el-form-item>
          <el-button
            size="small"
            type="primary"
            icon="el-icon-search"
            @click="dataRefresh"
            >查询</el-button
          >
        </el-form-item>
      </el-form>
    </div>

    <hltable
      :isNumber="false"
      :isChecked="false"
      :columnEdit="TableOptions.columnEdit"
      :formSearch="form"
      :refreshFlag="TableOptions.refreshFlag"
      :tableColumn="TableOptions.column"
      :selectedRows="TableOptions.selectedRows"
      :apiurl="TableOptions.apiurl"
      @getSelectedRows="getSelectedRows"
    >
    </hltable>
  </div>
</template>

<script>
import { hltable } from "@/components";
export default {
  name: "jjfwgdgl",
  components: { hltable },
  data() {
    return {
      loading: false,
      // 查询条件
      form: {
        name: "",
        idCard: "",
      },

      // 任务状态：0：未开启 1：进行中 2：已结束
      TASK_STATUS: [
        { value: 1, label: "未开始" },
        { value: 2, label: "执行中" },
        { value: 3, label: "暂停" },
        { value: 4, label: "待审核" },
        { value: 5, label: "驳回" },
        { value: 6, label: "结束" },
      ],
      // 列表设置
      TableOptions: {
        selectedRows: [],
        apiurl: "jtcw/getLiveDataList",
        refreshFlag: "",
        columnEdit: false,
        column: [
          {
            label: "老人姓名",
            prop: "name",
            checked: true,
          },
          {
            label: "老人身份证",
            prop: "idCard",
            checked: true,
          },
          {
            label: "老人手机号",
            prop: "phone",
            checked: true,
          },
          {
            label: "批次",
            prop: "batchName",
            checked: true,
          },
          {
            label: "合约号",
            prop: "code",
            checked: true,
          },
          {
            label: "合约次数",
            prop: "contractNum",
            checked: true,
          },
          {
            label: "有效时长",
            prop: "effectiveTime",
            checked: true,
          },
          {
            label: "签约时间",
            prop: "signTime",
            checked: true,
            formatter: this.timeFormatter,
          },
          {
            label: "服务批次余额",
            prop: "serverBalanceMoney",
            checked: true,
          },
          {
            label: "服务批次剩余时间",
            prop: "serverBalanceTime",
            checked: true,
          },
          {
            label: "服务项数",
            prop: "serverItmNum",
            checked: true,
          },
          {
            label: "服务金额",
            prop: "serverMoney",
            checked: true,
          },
          {
            label: "服务次数",
            prop: "serviceNum",
            checked: true,
          },
          {
            label: "工单状态",
            prop: "status",
            checked: true,
            formatter: (row, column, cellValue) => {
              return this.TASK_STATUS.find((item) => item.value == cellValue)
                ?.label;
            },
          },
          {
            label: "上门人次",
            prop: "visitNumber",
            checked: true,
          },
        ],
      },
      OrganList: [],
    };
  },
  async mounted() {
    await this.loadData();
    this.dataRefresh();
  },
  methods: {
    // 查询
    dataRefresh() {
      this.TableOptions.refreshFlag = new Date().getTime().toString();
    },
    // 时间格式化
    timeFormatter(row, column, cellValue) {
      return cellValue ? this.$timestampTostr(cellValue) : "";
    },
    // 加载数据
    async loadData() {
      // const OrganData = await this.$store.dispatch(
      //   "jjyl/getAllliveServiceOrgan",
      //   {}
      // );
      // if (OrganData.success) {
      //   this.OrganList = OrganData.result;
      // }
    },

    // 获取选中项
    getSelectedRows(rows) {
      this.TableOptions.selectedRows = rows;
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/styles/tabletoptool.scss";
</style>
