import * as api from "@/api/jtcw";
const actions = {
  /**
   * 服务组织
   * @param {http://192.168.3.247:3000/project/2623/interface/api/cat_7443} data
   * @returns
   */
  // 分页列表
  getbedServiceOrganList({}, parm) {
    return new Promise((resolve, reject) => {
      api
        .getbedServiceOrganList(parm)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  // 列表数据
  getAllbedServiceOrgan({}, parm) {
    return new Promise((resolve, reject) => {
      api
        .getAllbedServiceOrgan(parm)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  // 新增/修改
  savebedServiceOrgan({}, parm) {
    return new Promise((resolve, reject) => {
      api
        .savebedServiceOrgan(parm)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  // 删除
  delbedServiceOrgan({}, parm) {
    return new Promise((resolve, reject) => {
      api
        .delbedServiceOrgan(parm)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  // 导出
  downloadbedServiceOrgan({}, parm) {
    return new Promise((resolve, reject) => {
      api
        .downloadbedServiceOrgan(parm)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  /**
   * 服务人员
   * @param {http://192.168.3.247:3000/project/2623/interface/api/cat_7471} data
   * @returns
   */
  // 分页列表
  getbedServicePersonList({}, parm) {
    return new Promise((resolve, reject) => {
      api
        .getbedServicePersonList(parm)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  // 新增/修改
  savebedServicePerson({}, parm) {
    return new Promise((resolve, reject) => {
      api
        .savebedServicePerson(parm)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  // 删除
  delbedServicePerson({}, parm) {
    return new Promise((resolve, reject) => {
      api
        .delbedServicePerson(parm)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  // 导出
  downloadbedServicePerson({}, parm) {
    return new Promise((resolve, reject) => {
      api
        .downloadbedServicePerson(parm)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  /**
   * 服务批次包
   * @param {http://192.168.3.247:3000/project/2623/interface/api/cat_7457} data
   * @returns
   */
  // 分页列表
  getbedServiceBatchList({}, parm) {
    return new Promise((resolve, reject) => {
      api
        .getbedServiceBatchList(parm)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  // 列表数据
  getAllbedServiceBatch({}, parm) {
    return new Promise((resolve, reject) => {
      api
        .getAllbedServiceBatch(parm)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  // 分配
  saveBatchRel({}, parm) {
    return new Promise((resolve, reject) => {
      api
        .saveBatchRel(parm)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  // 新增/修改
  savebedServiceBatch({}, parm) {
    return new Promise((resolve, reject) => {
      api
        .savebedServiceBatch(parm)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  // 删除
  delbedServiceBatch({}, parm) {
    return new Promise((resolve, reject) => {
      api
        .delbedServiceBatch(parm)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  /**
   * 服务项目
   * @param {http://192.168.3.247:3000/project/2623/interface/api/cat_7464} data
   * @returns
   */
  // 分页列表
  getbedServiceItemList({}, parm) {
    return new Promise((resolve, reject) => {
      api
        .getbedServiceItemList(parm)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  // 新增/修改
  savebedServiceItem({}, parm) {
    return new Promise((resolve, reject) => {
      api
        .savebedServiceItem(parm)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  // 删除
  delbedServiceItem({}, parm) {
    return new Promise((resolve, reject) => {
      api
        .delbedServiceItem(parm)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  // 列表数据
  getAllbedServiceItem({}, parm) {
    return new Promise((resolve, reject) => {
      api
        .getAllbedServiceItem(parm)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  /**
   * 居家-合约管理
   * @param {http://192.168.3.247:3000/project/2623/interface/api/cat_7478} data
   * @returns
   */
  // 分页列表
  getbedContractList({}, parm) {
    return new Promise((resolve, reject) => {
      api
        .getbedContractList(parm)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  // 签约/修改
  savebedContract({}, parm) {
    return new Promise((resolve, reject) => {
      api
        .savebedContract(parm)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  // 获取批次下的项目
  getItemByBathId({}, parm) {
    return new Promise((resolve, reject) => {
      api
        .getItemByBathId(parm)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  // 获取批次下的组织
  getOrganByBathId({}, parm) {
    return new Promise((resolve, reject) => {
      api
        .getOrganByBathId(parm)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  /**
   * 居家-服务对象分配
   * @param {http://192.168.3.247:3000/project/2623/interface/api/cat_7485} data
   * @returns
   */
  // 分页列表
  getPersonList({}, parm) {
    return new Promise((resolve, reject) => {
      api
        .getPersonList(parm)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  // 获取服务组织下的人员
  getDataByOrganId({}, parm) {
    return new Promise((resolve, reject) => {
      api
        .getDataByOrganId(parm)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  // 分配人员/服务策略
  savePerson({}, parm) {
    return new Promise((resolve, reject) => {
      api
        .savePerson(parm)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  /**
   * 居家-居家服务工单
   * @param {http://192.168.3.247:3000/project/2623/interface/api/cat_7492} data
   * @returns
   */

  // PC-工单管理/生成工单
  addWorkOrder({}, parm) {
    return new Promise((resolve, reject) => {
      api
        .addWorkOrder(parm)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  // PC-工单审核分页列表
  getbedWorkOrderList({}, parm) {
    return new Promise((resolve, reject) => {
      api
        .getbedWorkOrderList(parm)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  // PC-工单审核列表导出
  downloadbedWorkOrder({}, parm) {
    return new Promise((resolve, reject) => {
      api
        .downloadbedWorkOrder(parm)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  // PC-工单详情
  getDataInfo({}, parm) {
    return new Promise((resolve, reject) => {
      api
        .getDataInfo(parm)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  // PC-工单审核
  workOrdeStartOrPauser({}, parm) {
    return new Promise((resolve, reject) => {
      api
        .workOrdeStartOrPauser(parm)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  // PC-结算管理分页
  getLiveDataList({}, parm) {
    return new Promise((resolve, reject) => {
      api
        .getLiveDataList(parm)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  /**
   * 智能设备
   * @param {http://192.168.3.247:3000/project/2623/interface/api/cat_7569} data
   * @returns
   */
  // 分页列表
  geteqElderlyList({}, parm) {
    return new Promise((resolve, reject) => {
      api
        .geteqElderlyList(parm)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  // 监测数据
  geteqElderlyData({}, parm) {
    return new Promise((resolve, reject) => {
      api
        .geteqElderlyData(parm)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};
export default {
  namespaced: true,
  actions,
};
