<template>
  <div
    v-loading="loading"
    element-loading-text="加载中"
    element-loading-spinner="el-icon-loading"
    element-loading-background="rgba(0, 0, 0, 0.8)"
    class="hl-full-container flexVertical"
  >
    <!-- 表单s -->
    <el-dialog
      title="历史高龄补贴发放记录"
      :visible.sync="dialogFormVisible"
      :modal="true"
      custom-class="el-dialog-hisServ"
      :destroy-on-close="true"
      @close="handleClose"
      width="60%"
      top="10vh"
    >
      <HistoryService :idCard="idCard" />
    </el-dialog>
    <!-- 表单e -->
    <div class="search">
      <el-form :model="form" ref="form" label-width="80px" inline>
        <el-form-item label="网格：">
          <el-cascader
            size="small"
            style="flex: 1"
            :options="areaData"
            v-model="areaCode"
            placeholder="请选择网格编码"
            clearable
            :props="{ value: 'code', label: 'name', checkStrictly: true }"
            :show-all-levels="false"
            @change="cascaderChanged"
          >
          </el-cascader>
        </el-form-item>
        <el-form-item label="身份证号">
          <el-input
            clearable
            size="small"
            v-model="form.idCard"
            placeholder="请输入身份证号"
          ></el-input>
        </el-form-item>
        <el-form-item label="是否发放">
          <el-select
            v-model="form.btFlag"
            size="small"
            clearable
            placeholder="请选择"
          >
            <el-option key="1" label="是" value="1"> </el-option>
            <el-option key="0" label="否" value="0"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="补贴时间">
          <el-date-picker
            size="small"
            v-model="form.data"
            value-format="timestamp"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-button
            size="small"
            type="primary"
            icon="el-icon-search"
            @click="dataRefresh"
            >查询</el-button
          >
          <el-button
            size="small"
            icon="el-icon-refresh-left"
            @click="reset('Form')"
            >重置</el-button
          >
        </el-form-item>
      </el-form>
    </div>
    <div class="toolbar">
      <el-button
        type="success"
        plain
        size="mini"
        icon="el-icon-s-order"
        @click="Send"
        >发放</el-button
      >
      <el-button
        type="primary"
        plain
        size="mini"
        icon="el-icon-download"
        @click="derive"
        >导出</el-button
      >
    </div>
    <hltable
      :isNumber="false"
      :columnEdit="TableOptions.columnEdit"
      :formSearch="form"
      :refreshFlag="TableOptions.refreshFlag"
      :tableColumn="TableOptions.column"
      :selectedRows="TableOptions.selectedRows"
      :apiurl="TableOptions.apiurl"
      @getSelectedRows="getSelectedRows"
    >
      <div slot-scope="selected" slot="EditColumn">
        <el-tooltip effect="dark" content="补贴记录" placement="top">
          <el-button
            type="warning"
            icon="el-icon-search"
            size="mini"
            circle
            @click="Edit(selected.singlerow)"
          ></el-button>
        </el-tooltip>
      </div>
    </hltable>
  </div>
</template>

<script>
import { hltable } from "@/components";
import HistoryService from "./historyService";
export default {
  name: "GLBT",
  components: { hltable, HistoryService },
  data() {
    return {
      token: { token: localStorage.getItem("systoken") },
      dialogFormVisible: false,
      disabled: true,
      loading: false,
      //表单信息-s
      formData: {
        name: "",
        sex: "",
        age: "",
        phone: "",
        idCard: "",
        address: "",
        level: "",
        oldType: "",
      },
      //表单信息-e
      //功能树-s
      areaCode: [],
      areaData: [],
      //表单信息-e
      form: {
        idCard: "",
        areaCode: "",
        btFlag: "",
        btStartTime: "",
        btEndTime: "",
      },
      TableOptions: {
        selectedRows: [],
        apiurl: "api/getOldAgeAllowanceList",
        refreshFlag: "",
        columnEdit: true,
        column: [
          {
            label: "姓名",
            prop: "name",
            checked: true,
          },
          {
            label: "年龄",
            prop: "age",
            checked: true,
          },
          {
            label: "联系方式",
            prop: "phone",
            checked: true,
          },
          {
            label: "身份证号",
            prop: "idCard",
            checked: true,
          },
          {
            label: "能力等级",
            prop: "level",
            checked: true,
          },
          {
            label: "老人类别",
            prop: "oldType",
            checked: true,
          },
          {
            label: "地区",
            prop: "areaName",
            checked: true,
          },
          {
            label: "年龄段",
            prop: "ageBracket",
            checked: true,
          },
          {
            label: "上次补贴发放时间",
            prop: "subsidyTime",
            checked: true,
            formatter: this.timeFormatter,
          },
        ],
      },
      idCard: "",
    };
  },
  watch: {},
  created() {},
  mounted() {
    this.dataRefresh();
    this.getAreaTree();
  },
  destroyed() {},
  methods: {
    timeFormatter(row, column, cellValue) {
      return cellValue ? this.$timestampTostr(cellValue) : "";
    },
    getAreaTree() {
      this.$store
        .dispatch("api/getTree", {
          areaCode: this.$store.getters.user.areaCode,
        })
        .then((response) => {
          if (response.success) {
            this.areaData = response.result;
          } else {
            this.$message.error(response.message);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    dataRefresh() {
      if (this.form && typeof this.form === "object") {
        if (
          this.form.data &&
          Array.isArray(this.form.data) &&
          this.form.data.length === 2
        ) {
          this.form.btStartTime = this.form.data[0];
          this.form.btEndTime = this.form.data[1];
          // delete this.form.data;
        }
        if (!this.form.areaCode) {
          this.form.areaCode = this.$store.getters.user.areaCode;
        }
        this.TableOptions.refreshFlag = new Date().getTime().toString();
      }
    },
    derive() {
      this.exportBt();
    },
    async exportBt() {
      if (
        this.form.data &&
        Array.isArray(this.form.data) &&
        this.form.data.length === 2
      ) {
        this.form.btStartTime = this.form.data[0];
        this.form.btEndTime = this.form.data[1];
        // delete this.form.data;
      }
      const token = await this.$store.dispatch("user/getToken");
      this.$axios({
        method: "post",
        url: "/api/oldAgeAllowance/exportData",
        data: this.form,
        headers: {
          token: token,
          platform: "web",
        },
        responseType: "blob",
      })
        .then((response) => {
          if (response.headers["content-disposition"]) {
            const content = response.data;
            const blob = new Blob([content]); //构造一个blob对象来处理数据

            // fileName必用这种方式进行解析，否则乱码
            // Server.UrlEncode(filename);

            let contentDisposition = response.headers["content-disposition"];
            // fileName必用这种方式进行解析，否则乱码
            let fileName = window.decodeURI(
              contentDisposition.substring(contentDisposition.indexOf("=") + 1)
            );
            //对于<a>标签，只有 Firefox 和 Chrome（内核） 支持 download 属性
            if ("download" in document.createElement("a")) {
              const link = document.createElement("a"); //创建a标签
              link.download = fileName; //a标签添加属性
              link.style.display = "none";
              link.href = URL.createObjectURL(blob);
              document.body.appendChild(link);
              link.click(); //执行下载
              URL.revokeObjectURL(link.href); //释放url
              document.body.removeChild(link); //释放标签
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    Send: function () {
      if (this.TableOptions.selectedRows.length == 0) {
        this.$message({
          type: "error",
          message: "请勾选下方表格中的数据",
        });
        return false;
      }
      const no = this.TableOptions.selectedRows.find(
        (a) => a.ageBracket === "年龄段不符合"
      );
      if (no) {
        this.$message({
          type: "error",
          message: "勾选数据中年龄段不符合",
        });
        return;
      }
      var elderlyInfoId = [];
      this.TableOptions.selectedRows.forEach((key) => {
        elderlyInfoId.push(key.id);
      });
      this.$confirm("确定要进行补贴发放吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.$store
          .dispatch("api/saveOldAgeAllowance", { elderlyInfoId: elderlyInfoId })
          .then((response) => {
            if (response.success) {
              this.$message.success("操作成功");
              this.dataRefresh();
            } else {
              this.$message.error(response.message);
            }
          })
          .catch((error) => {
            console.log(error);
          });
      });
    },
    getSelectedRows(rows) {
      this.TableOptions.selectedRows = rows;
    },
    reset() {
      // this.$refs['form'].resetFields()
      // 比上方获取demo的清理方式更彻底
      const th = this;
      Object.keys(this.form).forEach(function (key) {
        th.form[key] = "";
      });
    },
    cascaderChanged() {
      var [...village] = this.areaCode;
      this.form.areaCode = village.pop() || "";
    },
    Edit(row) {
      this.idCard = row.idCard;
      this.dialogFormVisible = true;
    },
    handleClose() {
      this.resetForm();
    },
    resetForm() {
      this.formData = {
        id: "",
        score: 0,
      };
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/styles/tabletoptool.scss";
</style>
