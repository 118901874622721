<template>
    <div>
        <el-drawer :title="title" :visible.sync="drawer" :direction="direction" :size=drawerSize title-font-size="100px"
            :append-to-body="true" @close="resetForm">
            <div class="content">
                <slot></slot>
            </div>
            <div class="button-right" v-if="buttonIsShow">
                <el-button type="primary" @click="submitForm('ruleForm')">{{ submitFormName }}</el-button>
                <el-button @click="resetForm('ruleForm')">{{ resetFormName }}</el-button>
            </div>
        </el-drawer>
    </div>
</template>

<script>
export default {
    name: 'drawer',

    data() {
        return {
            direction: 'rtl',
            drawer: false
        };
    },
    props: {
        title: {
            type: String
        },
        buttonIsShow: {
            type: Boolean,
            default: false
        },
        submitFormName: {
            type: String,
            default: '修改'
        },
        resetFormName: {
            type: String,
            default: '取消'
        },
        drawerSize: {
            type: String,
            default: '60%'
        }
    },

    mounted() {

    },

    methods: {
        submitForm(e) {
            this.$emit('submitForm', e)
        },
        resetForm(e) {
            this.drawer = false
            this.$emit('resetForm', e)
        }
    },
};
</script>

<style lang="scss" scoped>
.button-right {
    height: 3rem;
    float: right;
}

.content {
    height: calc(100% - 3rem);
    overflow: auto;
}

::v-deep .el-drawer__header {
    font-size: 20px;
    padding: 0
}

::v-deep .el-drawer {
    padding: 10px;
}
</style>