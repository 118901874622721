<template>
  <div
    v-loading="loading"
    element-loading-text="加载中"
    element-loading-spinner="el-icon-loading"
    element-loading-background="rgba(0, 0, 0, 0.8)"
    class="hl-full-container flexVertical"
  >
    <!-- 表单s -->
    <el-drawer
      :title="dailogTitle"
      :visible.sync="dialogFormVisible"
      direction="rtl"
      size="100%"
      :modal="false"
      :destroy-on-close="true"
      class="el-drawer-hl"
      @close="handleClose"
    >
      <el-form
        ref="elForm"
        :model="formData"
        :disabled="submitType == 'view' ? true : false"
        :rules="rules"
        size="medium"
        class="padding15"
        label-width="120px"
      >
        <el-col :span="24">
          <el-row>
            <el-col :span="12">
              <el-form-item label="机构名称" prop="name">
                <el-input
                  v-model="formData.name"
                  placeholder="请输入机构名称"
                  clearable
                  :style="{ width: '100%' }"
                >
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="负责人" prop="director">
                <el-input
                  v-model="formData.director"
                  placeholder="请输入负责人"
                  clearable
                  :style="{ width: '100%' }"
                >
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="负责人电话" prop="directorTel">
                <el-input
                  v-model="formData.directorTel"
                  placeholder="请输入负责人电话"
                  clearable
                  :style="{ width: '100%' }"
                >
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="性质" prop="nature">
                <el-input
                  v-model="formData.nature"
                  placeholder="请输入性质"
                  clearable
                  :style="{ width: '100%' }"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="二级分类" prop="secondClass">
                <el-input
                  v-model="formData.secondClass"
                  placeholder="请输入二级分类"
                  clearable
                  :style="{ width: '100%' }"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="法定代表人" prop="legalRepresent">
                <el-input
                  v-model="formData.legalRepresent"
                  placeholder="请输入法定代表人"
                  clearable
                  :style="{ width: '100%' }"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="简称" prop="abbreviation">
                <el-input
                  v-model="formData.abbreviation"
                  placeholder="请输入简称"
                  clearable
                  :style="{ width: '100%' }"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="社会信用代码" prop="code">
                <el-input
                  v-model="formData.code"
                  placeholder="请输入社会信用代码"
                  clearable
                  :style="{ width: '100%' }"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="所属区县" prop="county">
                <el-input
                  v-model="formData.county"
                  placeholder="请输入所属区县"
                  clearable
                  :style="{ width: '100%' }"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="所属街道/镇" prop="street">
                <el-input
                  v-model="formData.street"
                  placeholder="请输入所属街道/镇"
                  clearable
                  :style="{ width: '100%' }"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="所属社区/村" prop="community">
                <el-input
                  v-model="formData.community"
                  placeholder="请输入所属社区/村"
                  clearable
                  :style="{ width: '100%' }"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="详细地址" prop="detailAddress">
                <el-input
                  v-model="formData.detailAddress"
                  placeholder="请输入详细地址"
                  clearable
                  :style="{ width: '100%' }"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="机构类型" prop="operation">
                <el-select
                  v-model="formData.operation"
                  filterable
                  clearable
                  :style="{ width: '100%' }"
                  placeholder="请选择机构类型"
                >
                  <el-option
                    v-for="item in typeList"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="坐标经度" prop="lon">
                <el-input
                  v-model="formData.lon"
                  placeholder="请输入坐标经度"
                  clearable
                  :style="{ width: '100%' }"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="坐标纬度" prop="lat">
                <el-input
                  v-model="formData.lat"
                  placeholder="请输入坐标纬度"
                  clearable
                  :style="{ width: '100%' }"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="床位总数" prop="bedTotal">
                <el-input
                  v-model="formData.bedTotal"
                  placeholder="请输入床位总数"
                  clearable
                  :style="{ width: '100%' }"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="入住数量" prop="intoNum">
                <el-input
                  v-model="formData.intoNum"
                  placeholder="请输入入住数量"
                  clearable
                  :style="{ width: '100%' }"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="自理人数" prop="selfCareNum">
                <el-input
                  v-model="formData.selfCareNum"
                  placeholder="请输入自理人数"
                  clearable
                  :style="{ width: '100%' }"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="半失能人数" prop="halfDisabledNum">
                <el-input
                  v-model="formData.halfDisabledNum"
                  placeholder="请输入半失能人数"
                  clearable
                  :style="{ width: '100%' }"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="失能人数" prop="disabilityNum">
                <el-input
                  v-model="formData.disabilityNum"
                  placeholder="请输入失能人数"
                  clearable
                  :style="{ width: '100%' }"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-col>

        <el-col :span="24">
          <el-row>
            <el-form-item size="large" style="text-align: center">
              <el-button
                v-show="submitType != 'view' ? true : false"
                class="qconfirm"
                type="primary"
                size="medium"
                @click="submitForm()"
                >提交</el-button
              >
              <el-button
                v-show="submitType != 'view' ? true : false"
                class="qcancle"
                size="medium"
                @click="resetForm()"
              >
                重置
              </el-button>
            </el-form-item>
          </el-row>
        </el-col>
      </el-form>
    </el-drawer>

    <div class="search">
      <el-form label-width="100px" :model="form" ref="form" inline>
        <el-form-item label="机构名称">
          <el-input
            size="small"
            clearable
            v-model="form.name"
            placeholder="请输入机构名称"
          ></el-input>
        </el-form-item>
        <el-form-item label="法定代表人">
          <el-input
            size="small"
            clearable
            v-model="form.legalRepresent"
            placeholder="请输入法定代表人"
          ></el-input>
        </el-form-item>
        <el-form-item label="社会信用代码">
          <el-input
            size="small"
            clearable
            v-model="form.code"
            placeholder="请输入社会信用代码"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button
            size="small"
            type="primary"
            icon="el-icon-search"
            @click="dataRefresh"
            >查询</el-button
          >
        </el-form-item>
      </el-form>
    </div>
    <div class="toolbar">
      <el-button
        type="primary"
        plain
        size="mini"
        icon="el-icon-plus"
        @click="Add"
        >新增</el-button
      >
      <el-button
        type="danger"
        plain
        size="mini"
        icon="el-icon-delete"
        @click="Del"
        >删除</el-button
      >
    </div>
    <hltable
      :isNumber="false"
      :columnEdit="TableOptions.columnEdit"
      :formSearch="form"
      :refreshFlag="TableOptions.refreshFlag"
      :tableColumn="TableOptions.column"
      :selectedRows="TableOptions.selectedRows"
      :apiurl="TableOptions.apiurl"
      @getSelectedRows="getSelectedRows"
    >
      <div slot-scope="selected" slot="EditColumn">
        <el-tooltip effect="dark" content="查看" placement="top">
          <el-button
            type="warning"
            size="mini"
            icon="el-icon-search"
            circle
            @click="View(selected.singlerow)"
          ></el-button>
        </el-tooltip>
        <el-tooltip effect="dark" content="编辑" placement="top">
          <el-button
            type="primary"
            icon="el-icon-edit"
            size="mini"
            circle
            @click="Edit(selected.singlerow)"
          ></el-button>
        </el-tooltip>
      </div>
    </hltable>
  </div>
</template>

<script>
import { hltable } from "@/components";
export default {
  name: "fwrygl",
  components: { hltable },
  data() {
    return {
      loading: false,
      token: { token: localStorage.getItem("systoken") },
      // 表单内容
      dailogTitle: "",
      dialogFormVisible: false,
      submitType: "",
      // 查询条件
      form: {
        name: "",
        legalRepresent: "",
        code: "",
      },
      // 新增/修改 表单
      formData: {
        name: "",
        director: "",
        directorTel: "",
        nature: "",
        secondClass: "",
        legalRepresent: "",
        abbreviation: "",
        code: "",
        county: "",
        street: "",
        community: "",
        detailAddress: "",
        lon: "",
        lat: "",
        operation: "",
        bedTotal: "",
        intoNum: "",
        selfCareNum: "",
        halfDisabledNum: "",
        disabilityNum: "",
      },
      // 表单规则
      rules: {
        name: [
          {
            required: true,
            message: "请输入机构名称",
            trigger: "blur",
          },
        ],
        director: [
          {
            required: true,
            message: "请输入负责人",
            trigger: "blur",
          },
        ],
        directorTel: [
          {
            required: true,
            pattern:
              /^(0|86|17951)?(13[0-9]|14[579]|15[012356789]|16[2567]|17[01235678]|18[0-9]|19[012356789])[0-9]{8}$/,
            message: "请输入正确的手机号",
            trigger: "blur",
          },
        ],
      },
      // 列表设置
      TableOptions: {
        selectedRows: [],
        apiurl: "api/getelderlyCareInstitutionList",
        refreshFlag: "",
        columnEdit: true,
        column: [
          {
            label: "机构名称",
            prop: "name",
            checked: true,
            width: "150",
          },
          {
            label: "负责人",
            prop: "director",
            checked: true,
            width: "150",
          },
          {
            label: "负责人电话",
            prop: "directorTel",
            checked: true,
            width: "150",
          },
          {
            label: "性质",
            prop: "nature",
            checked: true,
            width: "150",
          },
          {
            label: "二级分类",
            prop: "secondClass",
            checked: true,
            width: "150",
          },
          {
            label: "法定代表人",
            prop: "legalRepresent",
            checked: true,
            width: "150",
          },
          {
            label: "简称",
            prop: "abbreviation",
            checked: true,
            width: "150",
          },
          {
            label: "社会信用代码",
            prop: "code",
            checked: true,
            width: "150",
          },
          {
            label: "所属区县",
            prop: "county",
            checked: true,
            width: "150",
          },
          {
            label: "所属街道/镇",
            prop: "street",
            checked: true,
            width: "150",
          },
          {
            label: "所属社区/村",
            prop: "community",
            checked: true,
            width: "150",
          },
          {
            label: "详细地址",
            prop: "detailAddress",
            checked: true,
            width: "150",
          },
          {
            label: "坐标经度",
            prop: "lon",
            checked: true,
            width: "150",
          },
          {
            label: "坐标纬度",
            prop: "lat",
            checked: true,
            width: "150",
          },
          {
            label: "机构类型",
            prop: "operation",
            checked: true,
            formatter: (row, column, cellValue) => {
              return this.typeList.find((item) => item.id == cellValue)?.name;
            },
            width: "150",
          },
          {
            label: "床位总数",
            prop: "bedTotal",
            checked: true,
            width: "150",
          },
          {
            label: "入住数量",
            prop: "intoNum",
            checked: true,
            width: "150",
          },
          {
            label: "自理人数",
            prop: "selfCareNum",
            checked: true,
            width: "150",
          },
          {
            label: "半失能人数",
            prop: "halfDisabledNum",
            checked: true,
            width: "150",
          },
          {
            label: "失能人数",
            prop: "disabilityNum",
            checked: true,
            width: "150",
          },
        ],
      },

      typeList: [
        { id: "1", name: "公办民营" },
        { id: "2", name: "公办公营" },
        { id: "3", name: "民办民营" },
      ],
    };
  },
  async mounted() {
    await this.loadData();
    this.dataRefresh();
  },
  methods: {
    // 查询
    dataRefresh() {
      this.TableOptions.refreshFlag = new Date().getTime().toString();
    },
    // 加载数据
    async loadData() {},
    // 新增
    Add() {
      this.setformType("add", "新增");
    },
    // 查看
    View(row) {
      this.setformType("view", "查看");
      this.loadform(row);
    },
    // 编辑
    Edit(row) {
      this.setformType("edit", "编辑");
      this.loadform(row);
    },
    // 设置表单状态
    setformType(type, title) {
      this.dialogFormVisible = true;
      this.dailogTitle = title;
      this.submitType = type;
    },
    loadform(row) {
      const rowinfo = Object.assign({}, row);
      this.formData = rowinfo;
    },
    // 弹窗关闭前处理
    handleClose() {
      this.resetForm();
    },
    // 删除
    Del() {
      if (this.TableOptions.selectedRows.length == 0) {
        this.$message({
          type: "error",
          message: "请勾选下方表格中的数据",
        });
        return false;
      }
      var ids = [];
      this.TableOptions.selectedRows.forEach((key) => {
        ids.push(key.id);
      });
      this.$confirm("确定要进行删除吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.$store
          .dispatch("api/delelderlyCareInstitution", { ids: ids })
          .then((response) => {
            if (response.success) {
              this.$message.success("操作成功");
              this.dataRefresh();
            } else {
              this.$message.error(response.message);
            }
          })
          .catch((error) => {
            console.log(error);
          });
      });
    },
    // 提交
    submitForm() {
      this.$refs["elForm"].validate((valid) => {
        if (valid) {
          this.loading = true;
          const copyForm = Object.assign({}, this.formData);
          if (this.submitType == "add") {
            copyForm.id = "";
          }
          this.$store
            .dispatch("api/saveelderlyCareInstitution", copyForm)
            .then((response) => {
              if (response.success) {
                this.$message.success("操作成功");
                this.dataRefresh();
              } else {
                this.$message.error(response.message);
              }
              this.dialogFormVisible = false;
              this.loading = false;
            })
            .catch((error) => {
              this.loading = false;
              console.log(error);
            });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    // 重置
    resetForm() {
      const th = this;
      Object.keys(this.formData).forEach(function (key) {
        th.formData[key] = "";
      });
    },

    // 获取选中项
    getSelectedRows(rows) {
      this.TableOptions.selectedRows = rows;
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/styles/tabletoptool.scss";
</style>
