<template>
  <div class="tableWrapper">
    <div class="table">
      <el-table
        height="100%"
        :data="TableDataSource"
        :row-key="rowKey"
        :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
        border
        highlight-current-row
        ref="multipleTable"
        class="success table-container"
        stripe
        @sort-change="sortChange"
        :header-cell-style="{
          color: '#000000',
          'font-size': '16px',
          'font-weight': 'bold',
        }"
        v-loading="loading"
        @row-click="handleRowClick"
        @selection-change="handleSelectionChange"
        :cell-style="cellStyle"
        @cell-click="cellClick"
      >
        <el-table-column
          type="selection"
          v-if="isChecked"
          :selectable="checkSelectable"
          width="55"
        >
        </el-table-column>
        <el-table-column
          v-if="isNumber"
          label="序号"
          width="75px"
          align="center"
        >
          <template slot-scope="scope">
            <span>{{ scope.$index + 1 }}</span>
          </template>
        </el-table-column>

        <template v-for="(item, index) in tableColumn">
          <el-table-column
            :key="index"
            :label="item.label"
            :prop="item.prop"
            :formatter="item.formatter"
            v-if="item.checked && item.columnType && item.columnType == 'img'"
            align="center"
          >
            <template slot-scope="scope">
              <img style="height: 40px" :src="scope.row[item.prop]" />
            </template>
          </el-table-column>

          <el-table-column
            :key="index"
            :label="item.label"
            :prop="item.prop"
            :formatter="item.formatter"
            v-if="item.checked && !item.columnType"
            :width="item.width ? item.width : ''"
            align="center"
            show-overflow-tooltip
          >
          </el-table-column>
        </template>
        <!-- 操作列 具名插槽、作用域插槽配合  s-->
        <template v-if="columnEdit">
          <el-table-column
            label="操作"
            :width="widthClick"
            fixed="right"
            align="center"
          >
            <template slot-scope="scope">
              <slot :singlerow="scope.row" name="EditColumn"></slot>
            </template>
          </el-table-column>
        </template>
        <!-- 操作列 e-->
      </el-table>
      <div class="pagination">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          :page-size="size"
          layout="total, sizes,jumper,->,prev, pager, next"
          :total="total"
        ></el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "hltable",
  components: {},
  props: {
    //树形表格必填项,选用唯一值作为参数
    rowKey: {
      type: String,
      required: false,
      default: null,
    },
    //查询条件参数
    formSearch: {
      type: Object,
      required: false,
      default: null,
    },
    //接口访问路径：/api/
    apiurl: {
      type: String,
      required: true,
    },
    //eltable的列配置，如下方注释
    // [{
    //   label: "label",
    //   prop: "Type",
    //   checked: true,
    //   formatter: function (row, column, cellValue, index) {
    //   }
    // }]
    tableColumn: {
      type: Array,
      required: true,
    },
    //控制表格刷新的标示
    refreshFlag: {
      type: String,
      required: false,
      default: "",
    },
    //是否添加动态列
    columnEdit: {
      type: Boolean,
      default: false,
    },
    //是否显示序号
    isNumber: {
      type: Boolean,
      default: false,
    },
    //是否开启单机选中行
    isClickSelect: {
      type: Boolean,
      default: false,
    },
    //是否显示checkbox
    isChecked: {
      type: Boolean,
      default: true,
    },
    //是否分页
    isPage: {
      type: Boolean,
      default: true,
    },
    // 操作列宽
    widthClick: {
      type: String,
      default: "200%",
    },
    // 单元格样式函数
    handleCellStyle: {
      type: Function,
      default: () => {},
    },
  },
  data: function () {
    return {
      v: 1,
      currentPage: 1,
      size: 20,
      tableRows: [],
      TableDataSource: [],
      loading: false,
      total: undefined,

      statusOptions: [
        { id: "-2", name: "撤回" },
        { id: "-1", name: "已驳回" },
        { id: "0", name: "待审批" },
        { id: "1", name: "已通过" },
        { id: "2", name: "测试准备" },
        { id: "3", name: "开始测试 " },
        { id: "4", name: "测试中" },
        { id: "5", name: "待评估" },
        { id: "6", name: "结束" },
      ],
    };
  },
  watch: {
    refreshFlag: {
      handler: function () {
        this.getTableDataSource();
      },
    },
  },
  created() {},
  mounted() {},
  destroyed() {},
  methods: {
    //该方法写死的,后期改为方法注入
    checkSelectable(row) {
      if (row.url) {
        return row.refundStatus == 0;
      } else {
        return 1;
      }
    },
    formatStatus(id) {
      const status = this.statusOptions.find((a) => a.id === id);
      return status.name;
    },
    statusClass(status) {
      var className = "status-s0";
      switch (status) {
        case "0":
          className = "status-s0";
          break;
        case "1":
          className = "status-s1";
          break;
        case "2":
          className = "status-s2";
          break;
        case "3":
          className = "status-s3";
          break;
        case "4":
          className = "status-s4";
          break;
        case "5":
          className = "status-s5";
          break;
        case "6":
          className = "status-s6";
          break;
        case "-1":
          className = "status-s01";
          break;
        case "-2":
          className = "status-s02";
          break;
      }
      return className;
    },
    getTableDataSource() {
      this.loading = true;
      const searchparm = {};
      const form = Object.assign({}, this.formSearch);
      searchparm.query = form;
      searchparm.currentPage = this.currentPage;
      searchparm.size = this.size;
      this.$store
        .dispatch(this.apiurl, searchparm)
        .then((response) => {
          this.loading = false;
          if (response.success) {
            if (this.isPage) {
              this.size = response.result.size;
              this.total = response.result.total;
              this.currentPage = response.result.currentPage;
              this.TableDataSource = response.result.rows;
            } else {
              this.size = response.size ? response.size : this.size;
              this.total = response.total
                ? response.total
                : response.result.length;
              this.TableDataSource = response.result;
            }
          } else {
            this.$message.error(response.message);
          }
        })
        .catch((error) => {
          console.log(error);
          this.loading = false;
        });
    },
    refresh() {
      this.currentPage = 1;
    },
    handleRowClick(row) {
      if (this.isClickSelect) {
        this.$refs.multipleTable.toggleRowSelection(row);
      }
    },
    handleSelectionChange: function (val) {
      this.tableRows = val;
      this.$emit("getSelectedRows", this.tableRows);
    },
    handleCurrentChange(currentPage) {
      this.currentPage = currentPage;
      this.getTableDataSource();
    },
    handleSizeChange(size) {
      this.size = size;
      this.getTableDataSource();
    },
    isactiveChanged(row) {
      this.$emit("isactiveChanged", row);
    },
    sortChange(prop) {
      console.log(prop);
    },
    cellClick(row, column, cell, event) {
      this.$emit("cellClick", row, column, cell, event);
    },
    cellStyle({ row, column, rowIndex, columnIndex }) {
      return this.handleCellStyle(row, column);
    },
  },
};
</script>

<style lang="scss" scoped>
.tableWrapper {
  flex: 1;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  position: relative;
}
.table {
  height: 100%;
  width: 100%;
  position: absolute;
  display: flex;
  flex-direction: column;
}
.pagination {
  margin-top: 10px;
}

.table-container {
  border: 0.5px solid #ede8e8;
  box-shadow: 1px 1px 1px #ede8e8;
  border-radius: 5px;
}

.switch-class {
}
.status {
  color: white;
  width: 65px;
  font-size: 14px;
  border-radius: 5px;
}
// 待审核
.status-s0 {
  background-color: #ffa500 !important;
}
// 已通过
.status-s1 {
  // background-color: #3BC372 !important;
  background-color: #2ecc71 !important;
}
// 测试准备
.status-s2 {
  background-color: #007bff !important;
}
// 开始测试
.status-s3 {
  background-color: #ff5722 !important;
}
// 测试中
.status-s4 {
  background-color: #808080 !important;
}
// 待评估
.status-s5 {
  background-color: #00bfff !important;
}
// 结束
.status-s6 {
  background-color: #add8e6 !important;
}
.status-s01 {
  background-color: darkgrey;
}
.status-s02 {
  background-color: cadetblue;
}
</style>
