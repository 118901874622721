import Vue from "vue";
import Router from "vue-router";
import Layout from '@/layout'

Vue.use(Router);

export const constantRoutes = [{
    path: '/redirect',
    component: Layout,
    hidden: true,
    children: [{
      path: '/redirect/:path(.*)',
      component: () => import('@/views/redirect/index')
    }]
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/login/index'),
    hidden: true
  },
  {
    path: "/DP",
    name: "DP",
    component: () => import("@/views/DP/index"),
  },
  {
    path: '*',
    component: () => import('@/views/error-page/404'),
    hidden:true,
    meta: {
      title: '401未找到',
    },
  },
  /*
  需要主页放开下方注释A
  跳转默认的加载页面放开注释B，配置redirect即可
  */

  /*A*/
  // {
  //   path: '/',
  //   component: Layout,
  //   redirect: '/home', 
  //   children: [{
  //     path: 'home',
  //     component: () => import('@/views/home/index'),
  //     name: 'home',
  //     meta: {
  //       title: '主页', 
  //       icon: 'el-icon-s-home',
  //       affix: true
  //     }
  //   }]
  // }

  /*B*/
  {
    path: '/',
    component: Layout,
    redirect: '/homePage/index',
  }
];
// export const asyncRoutes = [{
//     path: '/system',
//     component: Layout,
//     meta: {
//       title: '系统管理',
//       icon: 'el-icon-location',
//     },
//     children: [{
//       path: 'userManage',
//       component: () => import('@/views/system/userManage'),
//       name: 'userManage',
//       meta: {
//         activeMenu: '/system/userManage',
//         title: '用户管理',
//       }
//     }, {
//       path: 'roleManage',
//       component: () => import('@/views/system/roleManage'),
//       name: 'roleManage',
//       meta: {
//         title: '角色管理',
//       }
//     }, {
//       path: 'funManage',
//       component: () => import('@/views/system/funManage'),
//       name: 'funManage',
//       meta: {
//         title: '功能管理',
//       }
//     }]
//   },
//   {
//     path: '/home',
//     component: Layout,
//     children: [{
//       path: 'personal',
//       component: () => import('@/views/home/personal'),
//       name: 'personal',
//       meta: {
//         title: '个人设置',
//         icon: 'el-icon-s-home',
//       }
//     }],
//     hidden: true
//   },
// ]
const createRouter = () => new Router({
  scrollBehavior: () => ({
    y: 0
  }),
  routes: constantRoutes
})
const router = createRouter()
export default router;