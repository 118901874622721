import request from "@/utils/request";
// 居家养老服务监管

/**
 * 服务组织
 * @param {http://192.168.3.247:3000/project/2623/interface/api/cat_7443} data
 * @returns
 */
// 分页列表
export function getliveServiceOrganList(data) {
  return request({
    url: "/api/liveServiceOrgan/getDataList.json",
    method: "post",
    data,
  });
}
// 列表数据
export function getAllliveServiceOrgan(data) {
  return request({
    url: "/api/liveServiceOrgan/getAllData.json",
    method: "post",
    data,
  });
}
// 新增/修改
export function saveliveServiceOrgan(data) {
  return request({
    url: "/api/liveServiceOrgan/saveData.json",
    method: "post",
    data,
  });
}
// 删除
export function delliveServiceOrgan(data) {
  return request({
    url: "/api/liveServiceOrgan/deleteData.json",
    method: "post",
    data,
  });
}
// 导出
export function downloadliveServiceOrgan(data) {
  return request({
    url: "/api/liveServiceOrgan/exportData",
    responseType: "blob",
    method: "post",
    data,
  });
}

/**
 * 服务人员
 * @param {http://192.168.3.247:3000/project/2623/interface/api/cat_7471} data
 * @returns
 */
// 分页列表
export function getliveServicePersonList(data) {
  return request({
    url: "/api/liveServicePerson/getDataList.json",
    method: "post",
    data,
  });
}
// 新增/修改
export function saveliveServicePerson(data) {
  return request({
    url: "/api/liveServicePerson/saveData.json",
    method: "post",
    data,
  });
}
// 删除
export function delliveServicePerson(data) {
  return request({
    url: "/api/liveServicePerson/deleteData.json",
    method: "post",
    data,
  });
}
// 导出
export function downloadliveServicePerson(data) {
  return request({
    url: "/api/liveServicePerson/exportData",
    responseType: "blob",
    method: "post",
    data,
  });
}

/**
 * 服务批次包
 * @param {http://192.168.3.247:3000/project/2623/interface/api/cat_7457} data
 * @returns
 */
// 分页列表
export function getliveServiceBatchList(data) {
  return request({
    url: "/api/liveServiceBatch/getDataList.json",
    method: "post",
    data,
  });
}
// 列表数据
export function getAllliveServiceBatch(data) {
  return request({
    url: "/api/liveServiceBatch/getAllData.json",
    method: "post",
    data,
  });
}
// 新增/修改
export function saveliveServiceBatch(data) {
  return request({
    url: "/api/liveServiceBatch/saveData.json",
    method: "post",
    data,
  });
}
// 删除
export function delliveServiceBatch(data) {
  return request({
    url: "/api/liveServiceBatch/deleteData.json",
    method: "post",
    data,
  });
}
// 分配
export function saveBatchRel(data) {
  return request({
    url: "/api/liveServiceBatch/saveBatchRel.json",
    method: "post",
    data,
  });
}

/**
 * 服务项目
 * @param {http://192.168.3.247:3000/project/2623/interface/api/cat_7464} data
 * @returns
 */
// 分页列表
export function getliveServiceItemList(data) {
  return request({
    url: "/api/liveServiceItem/getDataList.json",
    method: "post",
    data,
  });
}
// 新增/修改
export function saveliveServiceItem(data) {
  return request({
    url: "/api/liveServiceItem/saveData.json",
    method: "post",
    data,
  });
}
// 删除
export function delliveServiceItem(data) {
  return request({
    url: "/api/liveServiceItem/deleteData.json",
    method: "post",
    data,
  });
}
// 列表数据
export function getAllliveServiceItem(data) {
  return request({
    url: "/api/liveServiceItem/getAllData.json",
    method: "post",
    data,
  });
}

/**
 * 居家-合约管理
 * @param {http://192.168.3.247:3000/project/2623/interface/api/cat_7478} data
 * @returns
 */
// 分页列表
export function getliveContractList(data) {
  return request({
    url: "/api/liveContract/getDataList.json",
    method: "post",
    data,
  });
}
// 签约/修改
export function saveliveContract(data) {
  return request({
    url: "/api/liveContract/saveData.json",
    method: "post",
    data,
  });
}
// 获取批次下的项目
export function getItemByBathId(data) {
  return request({
    url: "/api/liveServiceBatch/getItemByBathId.json",
    method: "post",
    params: data,
  });
}
// 获取批次下的组织
export function getOrganByBathId(data) {
  return request({
    url: "/api/liveServiceBatch/getOrganByBathId.json",
    method: "post",
    params: data,
  });
}

/**
 * 居家-服务对象分配
 * @param {http://192.168.3.247:3000/project/2623/interface/api/cat_7485} data
 * @returns
 */
// 分页列表
export function getPersonList(data) {
  return request({
    url: "/api/liveContract/getPersonList.json",
    method: "post",
    data,
  });
}
// 获取服务组织下的人员
export function getDataByOrganId(data) {
  return request({
    url: "/api/liveServicePerson/getDataByOrganId.json",
    method: "post",
    params: data,
  });
}
// 分配人员/服务策略
export function savePerson(data) {
  return request({
    url: "/api/liveContract/savePerson.json",
    method: "post",
    data,
  });
}
/**
 * 居家-居家服务工单
 * @param {http://192.168.3.247:3000/project/2623/interface/api/cat_7492} data
 * @returns
 */

// PC-工单管理/生成工单
export function addWorkOrder(data) {
  return request({
    url: "/api/liveContract/addWorkOrder.json",
    method: "post",
    data,
  });
}
// PC-工单审核分页列表
export function getliveWorkOrderList(data) {
  return request({
    url: "/api/liveWorkOrder/getDataList.json",
    method: "post",
    data,
  });
}
// PC-工单审核列表导出
export function downloadliveWorkOrder(data) {
  return request({
    url: "/api/liveWorkOrder/exportData.json",
    responseType: "blob",
    method: "post",
    data,
  });
}
// PC-工单详情
export function getDataInfo(data) {
  return request({
    url: "/api/liveWorkOrder/getDataInfo.json",
    method: "post",
    data,
  });
}
// PC-工单审核
export function workOrdeStartOrPauser(data) {
  return request({
    url: "/api/liveWorkOrder/workOrdeStartOrPauser.json",
    method: "post",
    data,
  });
}
// PC-结算管理分页
export function getLiveDataList(data) {
  return request({
    url: "/api/liveContract/getLiveDataList.json",
    method: "post",
    data,
  });
}